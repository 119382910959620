.root {
  margin-bottom: 1rem;
}

.root > div {
  margin-bottom: 1rem;
}

.formWrapper {
  margin-bottom: 0.25rem;
}

.inner {
  padding-left: 1.875rem;
}

.item {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  align-items: flex-start;
  grid-gap: 1rem;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--color-black300);
}

.deleteButton {
  color: var(--color-black400)
}

.deleteButton svg path {
  fill: currentColor;
}

.categoryInput {
  display: none;
}

.formSectionHeader {
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}