.archiveAccountModal {
  max-width: 500px;
  border: 1px solid #ebebf0;
  border-radius: 8px;
  z-index: 1101;
}

.overlay {
  z-index: 1100;
}

.title {
  color: #28293d;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
}

.subTitle {
  color: #394253;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  margin: 8px 0 32px 0;
}
.strong {
  font-weight: 700;
}

.containerImage {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.outlinedBtn {
  border: 1px solid #28293d;
  color: #28293d;
  margin-right: 16px;
  width: 115px;
}

.wineBtn {
  background-color: #fae9ef !important;
  color: #d52f6d !important;
  width: 115px;
}

.containerButtons {
  display: flex;
  justify-content: space-between;
}

.archiveBtn svg {
  width: 17px;
  height: 20px;
}
.archiveBtn path {
  fill: white;
}
