.container {
  display: flex;
  align-items: center;
}

.column {
  flex-direction: column;
  align-items: initial;
}

.fullWidth {
  flex: 1;
}

.description {
  cursor: pointer;
  border-radius: 4px;
  background-color: #fafafc;
  color: #718096;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 8px;
  margin-top: 4px;
  width: 153.8%;
}

.title {
  color: #718096;
  font-size: 14px;
  font-weight: 400;
  width: 35%;
  max-width: 35%;
  flex: 1;
  line-height: 24px;
  align-self: start;
  margin-top: 3px;
}

.containerOptions {
  position: absolute;
  width: 100%;
  display: none;
  z-index: 1;
  border: 1px solid #fafafc;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 12px 42px -4px rgba(24, 39, 75, 0.12),
    0 8px 18px -6px rgba(24, 39, 75, 0.12);
}

.showOptions {
  display: block;
  animation: animationBackground 0.2s forwards;
}

.relative {
  position: relative;
  flex: 1;
}

@keyframes animationBackground {
  0% {
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

.disableClick {
  pointer-events: none;
}
