.root {
    height: max-content;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 440px;
}

.title {
    margin: 8px 0;
    color: #28293d;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
}

.subTitle {
    display: flex;
    text-align: center;
    justify-content: center;
    color: #394253;
    font-size: 14px;
    line-height: 24px;
    max-width: 300px;
}

.btnWrapper {
    display: flex;
    margin-top: 16px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.textBtn {
    white-space: nowrap;
}

.iconStyle {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}
