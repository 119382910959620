.cardInfoManagers {
  width: 370px !important;
  top: -5px;
  left: -1px;
}

.cardInfoDescription {
  top: 35px;
  border: none !important;
}

.cardWorkSchedule {
  width: 306px !important;
  top: -8px;
  transform: translateY(-100%);
}

.cardCalendar {
  width: 306px !important;
  top: -380px;
}

.cardInfoTag {
  width: 370px !important;
  top: 0;
  transform: translateY(-100%);
}

.cardLanguages {
  top: calc(-40.5px * 5 + -40px);
  /* transform: translateY(-100%); */
  width: 300px !important;
}
