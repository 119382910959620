.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  min-width: 72px;
  height: 32px;
  color: var(--color-black600);
  padding: 8px 10px;
  border-radius: 8px;
  gap: 4px;
}

.root .button {
  min-width: 0;
  padding: 0;
  font-size: inherit;
  color: var(--color-gray-500);
  border: 0;
  background-color: transparent;
}

.icon svg {
  width: 1.5em;
  height: 1.5em;
}

.large {
  padding-left: 27px;
  padding-right: 27px;
}

.outlined {
  background-color: transparent;
  border: 1px solid var(--color-black100);
}

.contained {
  font-size: 14px;
  background-color: #F2F3F5;
}

.contained.primary {
  color: #ffffff;
  background-color: var(--color-primary);
}

.success {
  color: var(--color-green600);

}

.error {
  color: var(--color-pink500);
}

.info {
  color: var(--color-yellow500);
}

.primary {
  color: var(--color-primary);
}

.errorBg {
  background-color: var(--color-pink100);
}

.successBg {
  background-color: var(--color-green100);
}

.infoBg {
  background-color: var(--color-yellow100);
}

.primaryBg {
  background-color: var(--color-blue-100);
}

.contained .button {
  color: var(--color-primary);
}

.cursorPointer {
  cursor: pointer;
}
