.backDrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: var(--z-index-backdrop);
}

.root {
    position: absolute;
    top: 100%;
    left: 0;

    min-width: 125px;
    padding: 0.5rem 0;
    border: 1px solid #EBEDF0;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(40, 41, 61, 0.08), 0 20px 32px rgba(96, 97, 112, 0.24);

    overflow-y: auto;
    z-index: var(--z-index-menu);
}


.elevation1 {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
