.periodContainer {
  margin: 16px 0;
  font-size: 14px;
  line-height: 24px;
  color: #718096;
  display: flex;
  align-items: center;
}

.period {
  font-size: 14px;
  margin-left: 2px;
  line-height: 24px;
  font-weight: 500;
  color: #394253;
}

.containerButtons {
  display: flex;
  justify-content: space-between;
}

.outlinedBtn {
  border: 1px solid #28293d;
  color: #28293d;
}

.baseBtn {
  width: 110px;
}

.wrapper {
  padding: 16px;
}

.container {
  padding: 2px 8px;
  transition: 0.2s;

  color: #d8a12c;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  font-feature-settings: 'ss02' on, 'clig' off, 'liga' off;
}

.container:hover {
  transition: 0.2s;
  background-color: #fafafc;
}
