.header {
  min-height: 64px;
}
.header_info{
  padding: 0 24px!important;
}
.header_infoArrow{
  padding: 24px 24px 0;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
}

.overlayOpen {
  opacity: 1;
  z-index: 0;
  visibility: visible;
}

.overlayHidden {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.header {
  padding: 24px;
  /* top:0;
    z-index: 10;
    position: sticky;
    background-color: white; */
}

.drawer {
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 2000;
  position: fixed;
  box-sizing: border-box;
  flex-direction: column;
  /* padding: 24px; */
  background-color: #ffffff;
  width: 410px;
  flex-shrink: 0;

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.hidden {
  visibility: hidden;
  width: 240px;
  transform: translateX(240px);
  flex-shrink: 0;
  transition: 325ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.animate {
  visibility: visible;
  transform: none;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.drawerOpen {
}

.left {
  left: 0;
  right: auto;
}

.right {
  right: 0;
  left: auto;
}

.btnBase {
  width: 32px;
  height: 32px;
  background-color: #f1f8ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  height: 20px;
  width: 16px;
}

.arrowBack {
  background-color: #fafafc;
}

.rotate180 {
  transform: rotate(180deg);
  background-color: transparent;
}
.arrowBack:hover {
  background-color: transparent;
  transition: 0.2s;
}
