.rowStyles td {
  padding: 16px !important;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f2f5 !important;
  vertical-align: top;
}

.rowStyles:hover {
  transition: 0.2s;
  background-color: #fafafc;
}

.rowStyles {
  transition: 0.2s;
  position: relative;
  cursor: pointer;
}

.rowStyles td:first-child {
  border-left: 1px solid #f2f2f5 !important;
}

.rowStyles td:last-child {
  border-right: 1px solid #f2f2f5 !important;
}

.rootCell {
  gap: 0px !important;
  padding: 0px !important;
  /* align-items: ; */
  align-self: self-start;
}

.circle {
  width: 4px;
  height: 4px;
  margin: 0 4px 0 4px;
  border-radius: 4px;
  background-color: var(--color-black400);
}

.imgWrapper {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #e6f7f6;
}

.stageCard {
  width: 100px;
}

.emojiWrapper {
  width: 24px;
  height: 24px;
  background-color: #015bcb;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.row {
  display: flex;
  align-items: center;
}

.projectName {
  color: #28293d;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: #a0aec0;
  border-radius: 4px;
  margin: 0 4px;
}

.projectDate {
  margin-left: 26px;
  color: #718096;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 4px;
}
