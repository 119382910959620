.divide {
  height: 1px;
  background-color: #edf2f7;
}

.mB24 {
  margin-bottom: 24px;
}

.mV24 {
  margin: 24px 0;
}

.wrapper {
  padding: 0 24px;
  user-select: none;
}

.title {
  color: #394253;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.link {
  padding: 8px 30px;
  color: #0373fe;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
  text-transform: capitalize;
}

.link:hover {
  cursor: pointer;
}

.cardInfo {
  display: flex;
}

.cardTitle {
  color: #28293d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 8px;
}

.cardContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
}

.dragging {
  background-color: #f1f8ff !important;
}

.dragging svg path {
  fill: #4d9cfe;
}
.cardContainer::selection {
  background-color: red;
}

.cardContainer:hover {
  cursor: pointer;
}

.containerIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerIcon path {
  fill: #28293d;
  stroke: #28293d;
  stroke-width: 0.5px;
}

.plug {
  height: 32px;
}
