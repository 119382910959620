.input {
  border: 0;
  appearance: none;
  margin-right: 20px;
  max-width: 340px;
  width: 280px;
  padding: 8px 0;
  color: #28293d;
  caret-color: #0373fe;
}

.input:focus {
  outline: none;
}

.disableBorderBottom {
  border-bottom: 0px !important;
}

.divide {
  background-color: #cbd5e0;
  width: 1px;
  height: 24px;
  margin-left: 16px;
  transition: 0.2s;
}

.divideActive {
  transition: 0.2s;
  background-color: #28293d;
}

.plusIcon {
  cursor: pointer;
  width: 0px;
  height: 28px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}
.plusIcon svg {
  rotate: 45deg;
  width: 25px;
  height: 25px;
}

.plusIcon path {
  stroke: #28293d;
}

.plusIconActive {
  width: 28px;
  height: 28px;
  transition: 0.2s;
}

.searchIcon {
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}
.searchIconDisabled {
  width: 0px;
  transition: 0.2s;
}

.searchIcon svg {
  height: 27px;
  width: 27px;
}
.searchIcon path {
  transition: 0.2s;
  stroke: #cbd5e0;
}
.input::placeholder {
  color: #cbd5e0;
  font-weight: 300;
  transition: 0.2s;
}

.inputFocused::placeholder {
  color: #28293d;
  transition: 0.2s;
}

.containerInput {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #cbd5e0;
  transition: 0.2s;
}

.containerFocused {
  transition: 0.2s;
  border-bottom: 1px solid #28293d;
}

.searchIconFocused path {
  transition: 0.2s;
  stroke: #28293d;
}

.containerInput:hover {
  cursor: text;
}
