.navContainer {
  display: flex;
  flex-direction: row;
}

.navContainerMain {
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-bottom: 12px;
  border-bottom: 0.5px solid #cbd5e0;
  justify-content: space-between;
}

.firstTableBorder {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #ebebf0;
}

.tableBorderedFont p {
  font-size: 13px;
}

.nameFrom {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'ss02' on;
  color: #718096;
}

.noInformation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
}

.searchInput {
  background-color: transparent;
  border: none;
}

.tableBordered {
  border-bottom: 1px solid var(--color-border);
  min-width: 93px;
  max-width: 697px;
  border-top: 1px solid var(--color-border);
}

.tableBordered:hover {
  cursor: pointer;
}

.rowBackground p {
  text-transform: uppercase;
  color: #a0aec0;
}

.tableBorderedLeft {
  min-width: 93px;
  max-width: 697px;
}

.tableBorderedRight {
  min-width: 93px;
  max-width: 697px;
}

.tableCell {
  white-space: initial;
}

.activeRequests {
  padding-bottom: 9px;
  border-bottom: 3px solid #28293d;
  width: 126px;
}

.nonActive {
  width: 126px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.nonActive p {
  font-weight: 400;
}

.activeRequests p {
  font-weight: 500;
}

.dateReset {
  display: flex;
  gap: 8px;
  cursor: pointer;
}
