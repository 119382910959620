.deleteAccountModal {
    max-width: 500px;
    border: 1px solid #ebebf0;
    border-radius: 8px;
    z-index: 1101;
    padding: 32px 25px;
}

.deleteLeaveModal {
    padding: 32px 25px;
    box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16), 0 2px 4px 0 rgba(40, 41, 61, 0.04);
    border: 1px solid #ebebf0;
    border-radius: 8px;
    z-index: 1101;
    max-width: 380px;
}

.overlay {
    z-index: 1100;
}

.deleteLeaveModal .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2rem;
}
.deleteLeaveModal .header > svg{
    cursor: pointer;
}

.deleteLeaveModal .title {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    color: #28293d;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
}

.deleteLeaveModal .title > svg {
    width: 32px;
    height: 32px;
}

.title {
    color: #28293d;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
}

.subTitle {
    color: #394253;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    margin: 8px 0 16px 0;
}

.leaveInfo {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 2fr;
    margin: 24px 0;
    gap: .5rem;
    align-items: end;
    font-size: 14px;
    line-height: 14px;
}

.leaveInfo > div {
    display: grid;
    grid-gap: 10px;
}

.leaveInfo .leaveInfoArrow{
    font-weight: 700;
    padding: 0 10px;
}
.leaveInfo > div > p {
    color: #718096;
    font-weight: 400;

}

.leaveInfo > div > span {
    color: #28293D;
    font-weight: 700;
}

.strong {
    font-weight: 700;
}

.containerImage {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}
.containerTags{
    display: flex;
    justify-content: left;
    gap: .5rem;
    margin: 0 0 2rem ;
    padding: 0 0 1rem 4rem;;
    border-bottom: 1px solid #EDF2F7;
}
.containerTags > span {
    font-size: 12px;
    color: #2A303C;
    background-color: #EDF2F7;
    border-radius: 8px;
    padding: 4px 8px;
}

.outlinedBtn {
    border: 1px solid #28293d;
    color: #28293d;
    margin-right: 16px;
    width: 115px;
}

.wineBtn {
    background-color: #fae9ef !important;
    color: #d52f6d !important;
    width: 115px;
}

.containerButtons {
    display: flex;
    justify-content: space-between;
}
.leaveModalHead {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    border-bottom: 1px solid #EDF2F7;
    border-top: 1px solid #EDF2F7;
    padding: 1rem 0;
    margin: 1rem 0;
}
.leaveModalHead > img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    object-fit: cover;
}

 .leaveModalHead > div {
    display: grid;
    justify-items: start;
     font-weight: 700;
}

 .leaveModalHead > div > span:last-child {
    color: #718096;
    font-size: 14px;
     font-weight: 400;
}
