.root {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  cursor: pointer;
  user-select: none;
  appearance: none;
  text-decoration: none;
  font: inherit;
  line-height: 1.5;
  padding: .5rem;
  border-radius: var(--button-border-radius, 4px);
  background-color: transparent;
  border: 1px solid transparent;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.root:hover {
  background-color: #f0f0f0
}

.default {
  color: var(--color-base)
}

.inherit {
  color: inherit
}

.round {
  border-radius: 50%
}

.icon {
  display: inline-block;
  flex-shrink: 0;
  user-select: none;
  fill: currentColor;
  width: 1em;
  height: 1em;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.small {
  font-size: 1.25em;
}

.medium {
  font-size: 1.5em
}

.large {
  font-size: 2em
}

.disable {
  opacity: .6;
  pointer-events: none
}