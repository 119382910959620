.error{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    justify-items: center;
    grid-gap: 1rem ;
}
.error > span {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    color: var(--color-black700);
    text-align: center;
}
.error > p {
    color: var(--color-black600);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}
