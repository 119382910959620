.wrapper {
  position: relative;
  width: 100%;
}

.contentWrapper {
  display: flex;
  flex: 1;
  padding: 0 24px;
  overflow-y: auto;
}

.bottomWrapper {
  padding: 0 24px 24px 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
}

.form {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
}

.deleteBtn {
  background-color: #fae9ef;
  color: #d52f6d;
}

.deleteBtn:hover {
  border: 1px solid #fae9ef;
  background-color: transparent;
  color: #d52f6d;
}
