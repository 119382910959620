.hover:hover {
  cursor: pointer;
  background-color: #f2f3f5;
}

.rowWrapper {
  position: relative;
}

.modalWrapper {
  right: 20px;
}
