.wrapper_submission{
    margin-top: 1rem;
}
.wrapper_submission .createTime {
    color: #718096;
}
.wrapper_submission .profileImage{
    width: 24px;
    height: 24px;
    border-radius: 8px;
    object-fit: cover;
}
.wrapper_submission .tableRowsLastDelete{
    opacity: 0;
    transition: opacity 0.4ms ease-in-out;
}
.wrapper_submission .wrapper_submissionOpens:hover .tableRowsLastDelete{
    opacity: 1;
    transition: ease-in-out 2ms;
}
.wrapper_submission .tableRowsLastDelete > svg{
    width: 20px;
    height: 20px;
}
.tableRowsLast{
    max-width: 300px; /* Set the maximum width before applying ellipsis (adjust the value as needed) */
    overflow: hidden;
    text-overflow: ellipsis; /* Apply ellipsis if content overflows */
    white-space: nowrap; /* Prevent wrapping */
}
.block{
    padding: 12px 28px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.block .head {
    display: grid;
    grid-gap: 24px;
    align-items: center;

}
.block .head  > li {
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    color: var(--color-black500);
    font-size: 14px;
    line-height: 24px;
}
.block .head  > li:first-child{
    cursor: pointer;
}
.block .head  > li > span {
    color: #28293D;
}
.block .head > li > span {
    display: flex;
    align-items: center;
    gap: .5rem
}
.block .head > li > span > img {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    object-fit: cover;
}
.block .head > li:last-child {
    border-top: 1px solid #EBEBF0;
    border-bottom: 1px solid #EBEBF0;
    padding: 16px 0;
    grid-template-columns: 1fr;
    grid-gap: 16px;
}
.block .head > li:last-child > span {
    font-size: 16px;
    color: #28293D;
}
.block button{
    width: 100%;
    margin-bottom: 1rem;
}
