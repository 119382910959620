.root {
  display: flex;
  position: relative;
  background: var(--color-text-light200);
  border-radius: 4px;
  border: none;
  width: 350px;
  overflow: auto;
  font-family: var(--font-family-base);
  overflow-x: hidden;
  overflow-y: hidden;
}

.input {
  width: 100%;
  height: 100%;
  resize: none;
  outline: none;
  padding: 0.5rem;
  line-height: 1.5;
  font-family: var(--font-family-base);
  background-color: transparent;
  border: 0;
}

.fullWidth {
  width: 100%;
}

.root::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: var(--color-black400);
  font-family: 'Inter', sans-serif;
}

.control {
  display: block;
  min-width: 0;
  width: 100%;
  padding: 8px 16px;
  outline: none;
  border: 1px solid transparent;
  border-radius: 8px;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.outlined {
  background-color: transparent;
}

.offsetRight {
  padding-right: 45px;
}

.iconContainer {
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  pointer-events: none;
}

.iconButton {
  pointer-events: initial;
}

.iconFrame {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  padding: 8px;
  pointer-events: none;
}

.iconButton svg,
.icon {
  height: 18px;
  width: 18px;
}

.disabled {
  opacity: 0.4;
}


.control {
  padding-right: 45px;
}


.control {
  border-color: var(--color-error-light);
}


.iconContainer {
  color: var(--color-error-light);
}