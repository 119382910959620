.wrapper {
    padding: 0 0 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
}

.scroll {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    flex: 1;
}

.mBottom24 {
    margin-bottom: 24px;
}

.contentWrapper {
    padding: 0 24px;
    flex: 1;
}

.checkBox {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.checkBoxWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    cursor: pointer;
}

.disabledCheckBoxWrapper {
    cursor: not-allowed;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    opacity: 0.5;
}

.checkBoxWrapperText {
    margin-left: 4px;
}

.paperclipWrapper {
    display: flex;
    align-items: center;
    background-color: var(--color-text-light300);
    color: var(--color-black700);
    transition: 0.2s;
}

.paperclipWrapper:hover > svg > path {
    fill: #fff;
    transition: 0.2s;
}

.containerSaveBtn {
    padding: 0 24px;
}

.counter {
    height: 0;
    margin-top: 0;
    border-radius: 8px;
    margin-bottom: 0;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    transition: 0.2s;

    background-color: #e6f7f6;
    color: #02aaa4;
}

.showCounter {
    height: 32px;
    margin-top: -16px;
    margin-bottom: 24px;
    transition: 0.2s;
}

.counterInfinity {
    background-color: #f2f2f5;
    color: #28293d;
}

.counterError {
    background-color: #fae9ef;
    color: #d52f6d;
}
