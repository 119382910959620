.navContainer {
  display: flex;
  justify-content: space-between;
  overflow: auto;
  margin-bottom: 12px;
  border-bottom: 1px solid #edf2f7;
}

.table {
  padding-bottom: 150px;
  margin-bottom: -150px;
}
