.card {
  font-size: 12px;
  line-height: 18px;
  padding: 4px 8px;
  border-radius: 8px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.income {
  color: #1958e3;
  background-color: #f1f8ff;
}

.salary {
  color: #018782;
  background-color: #e6f7f6;
}

.transfer {
  color: #28293d;
  background-color: #fafafc;
}

.default {
  color: #d8a12c;
  background-color: #fbf5e9;
}

.overdue {
  background-color: #fae9ef;
  color: #d52f6d;
}

.pending {
  color: #1958e3;
  background-color: #f1f8ff;
}

.doneDate {
  color: #018782;
  background-color: #e6f7f6;
}

.onHold {
  color: #28293d;
  background-color: #fafafc;
}

.progress {
  color: #d8a12c;
  background-color: #fbf5e9;
}
