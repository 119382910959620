.cardInfo {
  display: flex;
}

.cardTitle {
  color: #28293d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 8px;
}

.cardContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0 4px 0;
  margin-bottom: 8px;
  border-bottom: 1px solid transparent;
}

.divide {
  height: 8px;
}

.drop {
  border-bottom: 1px solid #0373fe;
  transition: 0.1s;
}

.cardContainer:hover {
  cursor: pointer;
}

.containerIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerIcon path {
  fill: #28293d;
  stroke: #28293d;
  stroke-width: 0.5px;
}
