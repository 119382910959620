.containerOptions {
  position: absolute;
  /* width: 100%; */
  display: none;
  z-index: 1;
  border: 1px solid #fafafc;
  background-color: white;
  margin-top: 4px;
  border-radius: 8px;
  box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12),
    0px 8px 18px -6px rgba(24, 39, 75, 0.12);
  width: 308px;
}

.marginTop4 {
  margin-top: 4px;
}

.marginTop16 {
  margin-top: -16px;
}

.relative {
  position: relative;
}

.showOptions {
  display: block;
  animation: animationBackground 0.2s forwards;
}
@keyframes animationBackground {
  0% {
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

.wrapper {
  padding: 16px 17px 16px 17px;
}
.button {
  width: max-content;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  background-color: #fafafc;
  height: 40px;
  border-radius: 4px;
  padding: 11px 12px;
}

.buttonEvents {
  color: #718096;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  border-radius: 4px;
  margin: 0 4px 4px 0;
  text-transform: capitalize;
}

.divide {
  padding-bottom: 16px;
  border-bottom: 1px solid #fafafc;
  margin-bottom: 16px;
}
.wrapperButtons {
  display: flex;
  flex-direction: row;
  width: 250px;
  flex-wrap: wrap;
}
.button span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #718096;
  margin-left: 8px;
}

.boxWeekDay {
  width: 40px;
  height: 40px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #28293d;
}

.outlinedBtn {
  border: 1px solid #28293d;
  color: #28293d;
}

.baseBtn {
  width: 110px;
}

.clickableBox {
  cursor: pointer;
  transition: 0.1s;
}

.includedBoxLeft {
  font-weight: 700;
  background-color: #0373fe !important;
  color: white !important;
  border-radius: 0px 8px 8px 0px;
}

.includedBoxRight {
  font-weight: 700;
  background-color: #0373fe !important;
  color: white !important;
  border-radius: 8px 0px 0px 8px;
}

.round {
  border-radius: 8px !important;
}

.includedBoxCenter {
  background-color: #f1f8ff !important;
  font-weight: 500;
  color: #0373fe !important;
}
.includedBox:hover {
  color: white !important;
}

.clickableBox:hover {
  transition: 0.1s;
  background-color: #f1f8ff;
  color: #0373fe;
  font-weight: 600;
}
.periodContainer {
  margin: 16px 0;
  font-size: 14px;
  line-height: 24px;
  color: #718096;
  display: flex;
  align-items: center;
}

.currentDayBox {
  border-radius: 8px;
  background-color: #28293d;
  font-weight: 700;
  color: white;
}

.table {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.period {
  font-size: 14px;
  margin-left: 2px;
  line-height: 24px;
  font-weight: 500;
  color: #394253;
}

.menuDate {
  border-radius: 8px;
  border: 1px solid #fafafc;
  padding: 0;
  transform: translateX(50%);
  margin-top: 4px;
}

.containerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.arrowBtn:hover {
  background-color: #718096;
}

.arrowBtn:hover path {
  fill: white;
}

.arrowBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  margin-left: 8px;
  cursor: pointer;

  background: #fafafc;
  border-radius: 6px;
}

.arrowBtnRight {
  rotate: 180deg;
}

.arrowBtn path {
  fill: #edf2f7;
}

.containerButtons {
  display: flex;
  justify-content: space-between;
}

.title {
  color: #0373fe;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.baseDayBox {
  width: 40px;
  height: 40px;
  text-align: center;
  border: 1px solid #fafafc;
  border-collapse: collapse;
}

.dayBoxNotThisMonth {
  background-color: #f2f2f5;
}
