.dayWrapper {
    display: inline-flex;
    padding: 0 8px;
    height: 22px;
    background-color: var(--color-text-light200);
    border-radius: 200px;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.fileSection {
    display: flex;
    justify-content: space-between;
    padding: 8px 8px 8px 24px;
    margin: 8px 0 8px 0;
    transition: 0.2s;
}

.fileSection:hover {
    background-color: var(--color-text-light300);
    border-radius: 8px;
}

.fileWrapper {
    display: flex;
    grid-gap: 4px;
    flex-direction: row;
}

.fileInfoWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
}
.fileInfoName{
    color: #CBD5E0;
    font-size: 12px;
    line-height: 18px;
}

.line {
    width: 100%;
    height: 1px;
    background-color: var(--color-text-light400);
}

.fileOptionsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fileOptionsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    margin: 0 8px 0 8px;
    cursor: pointer;
}

.trashBackgroundColor {
    background-color: var(--color-french-wine-100);
}
.downloadBackgroundColor {
    background-color: var(--color-blue-100);
}
