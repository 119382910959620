.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  transition: 0.2s;
}

.copyIcon:hover path {
  stroke: #0373fe;
  transition: 0.2s;
}

.copyIcon svg {
  fill: none;
}

.copyIcon path {
  stroke: #718096;
  stroke-width: 2px;
}

.grid {
  display: grid;
  gap: 16px;
  margin-top: 16px;
}
.container {
  padding: 16px 24px;
  flex: 1;
}

.textAreaTitle {
  color: #28293d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 4px;
}

.comment {
  border-radius: 4px;
  background: #fafafc;
  padding: 8px;
  padding-left: 16px;
  color: #28293d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  height: 88px;
  resize: none;
  width: 100%;
  border: none;

  font-family: Inter;
}

/* List */

.mT16 {
  margin-top: 16px;
}

.gridList {
  overflow-y: auto;
  padding-top: 16px;
  height: 100%;
}

.wrapperCard {
  margin: 0 24px;
  padding: 8px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f5;
  transition: 0.2s;
  cursor: pointer;
}

.wrapperCard:hover {
  background-color: #fafafc;
  transition: 0.2s;
}

.cardImg {
  border-radius: 8px;

  width: 32px;
  height: 32px;
  margin-right: 4px;
  object-fit: cover;
}

.cardTitle {
  color: #28293d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

/* Profile */
.profileWrapper {
  flex: 1;
  margin-top: -32px;
}

.profileIcon {
  margin: 5px;
  height: 70px;
  width: 70px;
  border-radius: 12px;
  object-fit: cover;
}

.wrapperProfileIcon {
  display: flex;
  justify-content: center;
}

.profileInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 24px;
}

.profileUserName {
  color: #28293d;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 4px;
}

.profileRole {
  color: #018782;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  border-radius: 8px;
  background: #e6f7f6;
  padding: 4px 8px;

  margin: 16px 0;
}

.profileWrapperTech {
  display: flex;
  margin-bottom: 16px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.profileTechItem {
  padding: 4px 8px;
  background-color: #edf2f7;

  color: #2a303c;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 8px;
  margin: 4px 4px;
}

.profileEmail {
  color: #718096;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.wrapperAdditionalCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.containerAdditionalCard {
  border-radius: 4px;
  background: #fafafc;
  display: flex;
}

.containerAdditionalCard img {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  object-fit: cover;
}

.containerAdditionalCard p {
  color: #28293d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 4px;
}
.profileAdditionalTitle {
  color: #718096;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

/* ADD */

.addTechWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: -14px;
  margin-bottom: 16px;
}

.addTechContainer {
  border-radius: 8px;
  background-color: #fafafc;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 4px;
}
.addTechContainer p {
  color: #28293d;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  margin-right: 8px;
}

.addTechContainer svg {
  fill: #a0aec0;
  height: 16px;
  width: 16px;
  rotate: 45deg;
}

.addInvolvementTitle {
  color: #28293d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.addInvolvementContainer {
  display: flex;
  justify-content: space-between;
}

.containerRadio {
  display: flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
}

.radio {
  width: 22px;
  height: 22px;
  border: 1px solid #ebebf0;
  border-radius: 11px;
  transition: 0.2s;
}

.radioOn {
  border: 8px solid #28293d;
  transition: 0.2s;
}

.radioTitle {
  margin-left: 8px;
  color: #2a303c;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.editAvatar {
  display: flex;
  margin-bottom: 11px;
}
.editAvatar img {
  margin: 5px;
  width: 70px;
  height: 70px;
  border-radius: 12px;
}

.editAvatarInfo {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.editAvatarTitle {
  color: #28293d;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 4px;
}

.editAvatarSubTitle {
  color: #718096;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  display: flex;
  padding: 24px;
  justify-content: space-between;
}
