.pickedImageWrapper {
  display: flex;
  width: 100%;
  margin-top: 16px;
}

.pickedImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.size14 {
  font-size: 14px;
}

.mBottom8 {
  margin-bottom: 8px;
}

.blueText {
  color: var(--color-black700);
}

.image {
  width: 96px;
  height: 96px;
  cursor: pointer;
  border-radius: 16px;
  object-fit: cover;
}

.mTop4 {
  margin-top: 4px;
}

.redText {
  color: var(--color-pink500);
}

.cursorPointer {
  cursor: pointer;
}

.uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%234D9CFEFF' stroke-width='3' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
  /* border: 1px dashed var(--color-blue-500); */
  gap: 10px;
  color: var(--color-black300);
  cursor: pointer;
  background-color: #f2f7ff;
}

.uploaderTextWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.uploaderText {
  color: var(--color-blue-500);
  margin-left: 4px;
  font-weight: 500;
}

.boldText {
  font-weight: bold;
}

.underlinedText {
  text-decoration: underline;
}
