.row {
  display: flex;
}

.fixedSizeInput {
  flex: 1;
  /* max-width: 367px; */
  /* background-color: red; */
}

.maxWidthModal {
  max-width: 800px;
}

.m {
  margin-right: 24px;
}

.containerButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.rootResetBtn {
  color: #28293d;
}

.mRight {
  margin-right: 16px;
}
