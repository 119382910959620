.multiContainer {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 12px;
  user-select: none;
}

.multiContainer:hover {
  background-color: #fafafc;
  cursor: pointer;
}

.multiTitle {
  margin-left: 8px;
  color: #2a303c;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.marginLeft {
  padding-left: 34px;
}

.isAll {
  font-weight: 700;
}
