.root {
  display: flex;
  flex-direction: column;
  padding: var(--main-padding-y) var(--main-padding-x);
}

.container {
  display: flex;
  justify-content: center;
}

.form {
  border-radius: 8px;
  border: 1px solid#EBEBF0;
  padding: 32px 25px;
  margin-top: 44px;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  color: #28293d;

  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 24px;
}

.sizeInput {
  width: 365px;
}

.divide {
  height: 24px;
}
