.leftSection {
  flex: 1;
  height: 100%;
  border-right: 1px solid #f2f2f5;
  display: flex;
  flex-direction: column;
}
.rightSection {
  flex: 1;
  display: flex;
}

.leftHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 16px 24px;
}

.headerTitle {
  color: #28293d;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.btnIcon {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: #f1f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}
.btnIcon:hover {
  cursor: pointer;
  background-color: white;
  transition: 0.2s;
}
.btnIcon:hover svg {
  fill: gray;
  transition: 0.2s;
}

.btnIcon svg {
  fill: #0373fe;
  height: 20px;
  width: 20px;
}

.contaienrInput {
  padding: 8px 24px 0px;
}

.searchInput {
  width: 100%;
}

.scrollSection {
  flex: 1;
  overflow: hidden;
}

/* Link Card */
