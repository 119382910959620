.container {
  height: 40px;
  background-color: #fafafc;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.containerIconLeft {
  height: 40px;
  width: 24px;
  padding-left: 16px;
  padding-right: 8px;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.containerIconLeft svg {
  height: 24px;
  width: 24px;
}

.containerIconRight svg {
  height: 24px;
  width: 24px;
}
.containerIconLeft:hover {
  cursor: pointer;
}

.containerIconRight {
  height: 40px;
  width: 24px;
  padding-right: 16px;
  padding-left: 8px;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerIconRight svg {
  rotate: 180deg;
}

.containerIconRight:hover {
  cursor: pointer;
}

.counterValue {
  color: #28293d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
