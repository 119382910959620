.root {
  padding: var(--main-padding-y) var(--main-padding-x);
}

.form {
  max-width: 1000px;
}

.formInner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  margin-top: 1rem;
}

.formTextarea {
  width: 100%;
  padding-left: 2.125rem;
}

.formWrapper {
  margin-bottom: 1rem;
}

.formFooter {
  padding-top: 1.5rem;
  margin-top: 2rem;
  border-top: 1px solid var(--color-black300)
}

.root ul {
  list-style-type: disc;
}

.root ol {
  list-style-type: decimal;
}

.formSectionHeader {
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}