.status {
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  display: flex;
  align-items: center;
}

.emoji {
  margin-right: 8px;
  line-height: 16px;
  user-select: none;
}
