.wrapper {
  flex: 6;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.medicalLeaveMainColor {
  color: #d52f6d;
}

.medicalLeaveSecondColor {
  background-color: #fae9ef;
}

.daysOffMainColor {
  color: #e0b457;
}

.daysOffSecondColor {
  background-color: #fbf5e9;
}

.absenceDaysWrapper {
  display: flex;
  gap: 16px;
}
