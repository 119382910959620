.snackbar-wrapper {
  display: flex;
  align-items: center;
  margin: 8px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  transition: opacity 150ms, transform 150ms;
  pointer-events: none;
}

.snackbar-wrapper-top-left,
.snackbar-wrapper-top-center,
.snackbar-wrapper-top-right {
  top: 8px;
}

.snackbar-wrapper-bottom-left,
.snackbar-wrapper-bottom-center,
.snackbar-wrapper-bottom-right {
  bottom: 8px;
}

.snackbar-wrapper-top-left,
.snackbar-wrapper-bottom-left {
  justify-content: flex-start;
}

.snackbar-wrapper-top-center,
.snackbar-wrapper-bottom-center {
  justify-content: center;
}

.snackbar-wrapper-top-right,
.snackbar-wrapper-bottom-right {
  justify-content: flex-end;
}

.snackbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  min-width: 334px;
  max-width: 672px;
  text-align: left;
}

.snackbar__info {
  padding: 9px 0 9px 16px;
  display: flex;

  flex: 1;
}

.snackbar__type__error {
  background-color: #fae9ef;
  border: 1px solid #f7cada;
}
.snackbar__type__success {
  background-color: #e5f7f1;
  border: 1px solid #e5f7f1;
}
.snackbar__type__warning {
  background-color: #fbf5e9;
  border: 1px solid #f0daad;
}
.snackbar__type__info {
  background-color: #f1f8ff;
  border: 1px solid #4d9cfe;
}

/* Responsiveness based on Material Design */
@media (max-width: 480px), (max-width: 344px) {
  .snackbar {
    min-width: 100%;
  }
}

.snackbar__text {
  flex-grow: 1;
  pointer-events: auto;
  color: var(--black-black-700, #28293d);
  font-size: 14px;
  line-height: 22px;
}

.snackbar__info__icon {
  margin-right: 8px;
  margin-top: 1px;
}

.snackbar__close {
  cursor: pointer;
  pointer-events: auto;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.snackbar__close svg {
  height: 15px;
  width: 15px;
  fill: #718096;
}

@media (hover: hover) {
  .snackbar__close:hover:before {
    opacity: 0.08;
  }
}

/* CSSTransition classNames logic */
.snackbar-enter,
.snackbar-exit-active {
  opacity: 0;
}

.snackbar-enter-active {
  opacity: 1;
}

.snackbar-enter-top-left,
.snackbar-enter-top-center,
.snackbar-enter-top-right,
.snackbar-exit-active-top-left,
.snackbar-exit-active-top-center,
.snackbar-exit-active-top-right {
  transform: translateY(-16px);
}

.snackbar-enter-bottom-left,
.snackbar-enter-bottom-center,
.snackbar-enter-bottom-right,
.snackbar-exit-active-bottom-left,
.snackbar-exit-active-bottom-center,
.snackbar-exit-active-bottom-right {
  transform: translateY(16px);
}

.snackbar-enter-active-top-left,
.snackbar-enter-active-top-center,
.snackbar-enter-active-top-right,
.snackbar-enter-active-bottom-left,
.snackbar-enter-active-bottom-center,
.snackbar-enter-active-bottom-right {
  transform: translateY(0px);
}
