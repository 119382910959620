.input {
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: hsl(240, 25%, 98%);
  cursor: pointer;
  position: relative;
  height: 42px;
  border: 1px solid #fafafc;
  transition: 0.2s;
}

.disabled {
  background-color: #f2f2f5;
  cursor: not-allowed;
}

.disabledLabel {
  color: #a0aec0 !important;
}

.avatar {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  margin-right: 8px;
}

.text {
  color: #28293d;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  overflow-wrap: break-word;
  height: 24px;
}

.containerText {
  width: calc(100% - 40px);
}

.container {
  display: flex;
}

.chevron {
  height: 24px;
  width: 24px;
}

.placeholder {
  color: #a0aec0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.label {
  color: #28293d;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 4px;
}

.absolute {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 42px;
  transition: 0.2s;
  border-radius: 4px;
}

.absoluteFocused {
  top: -3px;
  left: -3px;
  height: 46px;
  width: calc(100% + 6px);
  border-radius: 6px;
  z-index: -1;
  background-color: #f2f2f5;
  transition: 0.2s;
}

.absoluteError {
  background-color: #fae8ee;
  transition: 0.2s;
}

.focused {
  transition: 0.2s;
  border-color: #28293d;
}

.redBorder {
  transition: 0.2s;
  border-color: #d52f6d;
}

.subTitle {
  color: var(--color-black500);
}

.required {
  color: var(--color-error);
  margin-left: 4px;
}

.requiredDisabled {
  color: #a0aec0;
}

.selectInput {
  background-color: transparent;
  border: none;
  height: 42px;
  outline: none;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #28293d;
}
.selectInput::placeholder {
  color: #a0aec0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.smallPadding {
  padding: 0 8px;
}

.smallContainerText {
  width: calc(100% - 24px);
}
