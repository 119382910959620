.root{
    width: 100%;
    padding: var(--main-padding-y) var(--main-padding-x);
}

.root .containerPlug {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.root .table{
    margin-top: 1rem;
}
.root > p:first-child{
    margin-bottom: 40px;
}

.root .into{
    display: grid;
    justify-items: center;
    margin-top: 3rem;
    grid-gap: 1rem;
}
.root .into > p{
    color: var(--color-black700);
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
}
