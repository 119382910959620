.root {
  font-family: 'Inter', sans-serif;
}

.h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 72px;
}

.h2 {
  font-weight: 600;
  font-size: 56px;
  line-height: 64px;
}

.h3 {
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
}

.h4 {
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
}

.h5 {
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  color: #2a303c;
}

.h6 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.body {
  font-size: 16px;
  font-weight: 400;
}

.boldLabel {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

.buttonText {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.smallBody {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.subHeading1 {
  font-size: 20px;
  font-weight: 700;
}

.subHeading2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.semiBoldBody {
  font-size: 16px;
  font-weight: 600;
  line-height: 155%;
}

.smallText {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.tinyText {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
}

.smallLabel {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
}

.uppercase {
  text-transform: uppercase;
}

.mediumLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.textLabel {
  font-weight: 500;
  font-size: 14px;
}

.largeLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.inherit {
  color: inherit;
}

.base {
  color: var(--color-base);
}

.primary {
  color: var(--color-primary);
}

.muted {
  color: var(--color-gray-500);
}

.lightMuted {
  color: var(--color-black400);
}

.error {
  color: var(--color-error);
}

.info {
  color: var(--color-yellow500);
}

.success {
  color: var(--color-green600);
}

.gray400 {
  color: var(--color-black400);
}

.gray {
  color: var(--color-black500);
}

.darkGray {
  color: var(--color-black600);
}

.dark {
  color: var(--color-black700);
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.gutterBottom {
  margin-bottom: 8px;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semiBold {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 2px;
}
