.wrapper {
  display: flex;
}

.containerTitle {
  color: #718096;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
}

.container {
  display: flex;
  align-items: center;
  user-select: none;
}

.container:hover {
  cursor: pointer;
}

.containerIcon {
  height: 16px;
  width: 16px;
  margin: 0px 8px;
}

.containerIcon path {
  fill: #cbd5e0;
  stroke: #cbd5e0;
  stroke-width: 0.4px;
}
.containerIcon svg {
  height: 18px;
  width: 18px;
}

.lastTitle {
  color: #28293d;
}

.lastTitle:hover {
  cursor: initial;
}
