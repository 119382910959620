.tableContainer {
  width: 100%;
  overflow: auto;
}

.root {
  width: 100%;
  border-bottom: 1px solid var(--color-border);
}

.root td {
  padding: 1rem 1rem;
  font-size: 0.875rem;
  color: var(--color-black600);
}

.root th[align='right'],
.root td[align='right'] {
  text-align: right;
}

.root th[align='center'],
.root td[align='center'] {
  text-align: center;
}

.root thead th {
  font-size: 0.75rem;
  padding: 16px;
  text-transform: uppercase;
  color: var(--color-black400);
  border-bottom: 0.5px solid #cbd5e0;
}

.bordered thead th {
  border: 1px solid var(--color-border);
}

.root tbody td {
  border-bottom: 0.5px solid #cbd5e0;
}
