.root {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}

.root + .root {
  border-top: 1px solid var(--color-text-light300);
}

.hover {
  cursor: pointer;
}

.hover:hover, .active {
  background-color: #f2f2f2;
}

.root svg {
  width: 20px;
  height: 20px;
  display: list-item;
}

.inherit {
  color: inherit;
}

.muted {
  color: #5D5F61;
}

.error {
  color: var(--color-error)
}