.documentWrapper {
    display: inline-flex;
    flex-direction: row;
    border: 1px solid #F2F2F5;
    border-radius: 8px;
    padding: 8px;
    margin: 16px 16px 0 0;
}

.image {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    object-fit: cover;
}

.documentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.imageWeight {
    color: var(--color-black300);
}

.fileIconWrapper {
    width: 40px;
    height: 40px;
}

.crossIconWrapper {
    margin-left: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.nameWrapper {
    word-break: break-all;
}
