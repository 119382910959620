.flexRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  height: 46px;
}

.select {
  border: 1px solid var(--color-black200);
  max-width: 132px;
  padding: 10px 8px;
}
.navContainer {
  display: flex;
  flex-direction: row;
}

.navContainerMain {
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-bottom: 12px;
  border-bottom: 1px solid #edf2f7;
  justify-content: space-between;
}

.activeRequests {
  padding-bottom: 9px;
  border-bottom: 3px solid #28293d;
  width: 126px;
}

.nonActive {
  width: 126px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.nonActive p {
  font-weight: 400;
}

.activeRequests p {
  font-weight: 500;
}

.dateReset {
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;
}

.chipContainer {
  height: 26px;
  transition: 0.2s;
}

.chipContainer:hover {
  opacity: 0.8;
  transition: 0.2s;
}
.tabItem {
  flex-shrink: 0;

  padding: 0.375rem 0;
  width: 8rem;
  min-width: 120px;
  border-radius: 0;
  border-bottom: 3px solid transparent;
  color: #28293d;
  font-weight: 300;

  white-space: nowrap;
}
.tabItemActive {
  border-bottom-color: var(--color-black700);
}

.font500 {
  font-weight: 500;
}
