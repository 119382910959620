.title {
  color: #a0aec0;
  font-size: 12px;
  font-weight: 400;
  padding: 8px 8px 4px 8px;
  line-height: 18px;
}

.focused {
  background-color: #fafafc;
}

.selectableCard {
  padding: 8px;
  cursor: pointer;
}

.selectableCard:hover {
  background-color: #fafafc;
}

.bgGray {
  /*background-color: #fafafc;*/
  width: fit-content;
}

.avatar {
  display: flex;
  align-items: center;
  color: #28293d;
  font-size: 14px;
  padding-right: 4px;
}

.avatarImg {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 4px;
  object-fit: cover;
}

.containerOptions {
  top: 78px;
}

.divide {
  height: 1px;
  background-color: #f2f2f5;
  margin: 16px 8px;
}

.containerButton {
  padding: 0 16px 16px 16px;
}

.btn {
  border-color: #28293d !important;
  color: #28293d !important;
}

.btn path {
  stroke: #28293d;
}

.search {
  padding: 8px;
  color: #28293d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  appearance: none;
  caret-color: #0373fe;
  background-color: #fafafc;
  width: 100%;
}
.search:focus {
  outline: none;
}

.header {
  background-color: #fafafc;
}

.wrap {
  padding: 8px 0 0 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 120px;
  background-color: #fafafc;
  overflow-y: scroll;
}

.containerCard {
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-right: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.main {
  max-height: calc(40px * 5 + 30px);
  overflow-y: auto;
  padding: 0;
}

.plug {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-size: 14px;
  color: #a0aec0;
}
