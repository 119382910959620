.root {
  display: flex;
  flex-direction: column;
  padding: var(--main-padding-y) var(--main-padding-x);
}

.title {
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
}
