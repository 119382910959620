.wrapper {
  display: grid;
  margin-top: 8px;
  flex-wrap: wrap;
  gap: 8px;
}
.arrow{
  transform: rotate(90deg);
  transition: .3s;
}
.arrowActive {
  transform: rotate(270deg);
  transition: .3s;
}
.container {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 0 6px;
}

.title {
  color: #718096;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
}

.gap {
  display: grid;
  gap: 16px;
  border-bottom: 1px solid #F2F2F5;
  padding-bottom: 10px;

}
