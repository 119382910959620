.wrapper {
  flex: 1;
  /* width: 814px; */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.title {
  color: var(--black-black-600, #394253);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.container {
  width: 750px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.rowCounter {
  display: flex;
}

.flex {
  flex: 1;
}

.mR24 {
  margin-right: 24px;
}

.mB22 {
  margin-bottom: 22px;
}

.inputTitle {
  color: #28293d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 4px;
}

.inputSubTitle {
  color: #718096;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 4px;
}

.containerComment {
  width: 100%;
  margin-bottom: -22px;
}
