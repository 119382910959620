.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ohNoText {
  font-weight: bold;
  font-size: 20px;
  color: #28293d;
}

.imgWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding-bottom: 100px;
}
