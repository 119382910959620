.root {
  display: flex;
  flex-direction: column;
  padding: var(--main-padding-y) var(--main-padding-x);
}

.title {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.iconArchive svg {
  height: 15px;
  width: 15px;
}

.iconArchive path {
  fill: #28293d;
  transition: 0.25s;
}

.btn:hover .iconArchive path {
  transition: 0.25s;
  fill: white;
}
.iconArchive {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
