.wrapper {
  padding: 24px 40px;
  user-select: none;
  margin-bottom: 6rem;
}

.table {
  padding-bottom: 150px;
  margin-bottom: -150px;
}

.menuWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.iconStyle {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.buttonText {
  color: var(--color-black700);
  margin-right: 16px;
  transition: 0.2s;
}

.buttonText:hover > svg > path {
  fill: #fff;
  transition: 0.2s;
}
.medicalLeaveSecondColor {
  background-color: var(--color-pink100) !important;
}

.daysOffLeaveSecondColor {
  background-color: var(--color-warm-yellow100) !important;
}

.drawer {
  height: 100% !important;
  margin-top: 0 !important;
  z-index: 1041 !important;
}

.containerTitle {
  margin-top: 24px;
  display: flex;
}

.containerRange {
  margin-top: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.daysOffSecondColor {
  background-color: #fbf5e9 !important;
}

.rootPlug {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nothingFoundIcon {
  width: 220px;
  height: 220px;
}

@media (max-width: 1050px) {
  .menuWrapper {
    justify-content: space-between;
    align-items: flex-start;
  }
  .profileWrapperRight {
    display: grid;
    grid-gap: 10px;
    justify-items: end;
  }
}

.disablePointerEvent {
  pointer-events: none;
}
