.container {
  border-radius: 12px;
  user-select: none;
  min-height: 41px;
  overflow: hidden;
  height: 41px;
  transition: 0.2s;
}

.containerOn {
  background: #f1f8ff;
}

.container .submenu a:first-child {
}

.submenu {
  padding-left: 24px;
  padding-top: 16px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  transition: 0.2s;
}

.submenuBase {
  padding-left: 24px;
  padding-top: 16px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  transition: 0.2s;
}

.subMenuSmall {
  padding-left: 8px;
  padding-right: 8px;
}

.subMenuSmall a {
}

.submenuInnerBtn {
  padding: 0px;
  height: 28px;
}

.textBoldOn {
  transition: 0.2s;
  font-weight: 500;
}

.textBold {
  transition: 0.2s;
}

.smallMenuItem {
  display: flex;
  justify-content: flex-end;
  font-size: 0;
  grid-gap: 0;
}

.smallMenuItem div div {
  margin-right: 0px !important;
}

.smallMenuItem {
  /* width: 40px !important; */
  /* background-color: red; */
}
