.overlay {
  z-index: 1050;
}

.modal {
  z-index: 1051;
  max-width: 860px;
  max-height: 70vh;
  height: 100%;

  overflow: initial;
  padding: 0px;
  display: flex;
  flex: 1;
  border: 1px solid #f2f2f5;
  user-select: none;
}

/* Link Card */
.linkCard {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #f2f2f5;
  transition: 0.2s;
}
.linkCard:hover {
  transition: 0.2s;
  background-color: #fafafc;
}

.linkInfoCard {
  display: flex;
}

.cardLinkTitle {
  color: #28293d;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 8px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  transition: 0.2s;
}

.copyIcon:hover path {
  stroke: #0373fe;
  transition: 0.2s;
}

.copyIcon svg {
  fill: none;
}

.copyIcon path {
  stroke: #718096;
  stroke-width: 2px;
}

.gridLinks {
  display: grid;
  gap: 16px;
  overflow-y: auto;
  padding-top: 16px;
  height: 100%;
}

.linkWrapper {
  padding: 0 24px;
}

.linksContainer {
  display: grid;
  gap: 8px;
}

.container {
  padding: 16px 24px;
  flex: 1;
}

.linksGrid {
  display: grid;
  gap: 16px;
}

.cardTitle {
  color: #718096;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
}

.addLinkComponent {
  display: grid;
  gap: 16px;
  padding: 10px 24px;
}

.textAreaTitle {
  color: #28293d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 4px;
}

.comment {
  border-radius: 4px;
  background: #fafafc;
  padding: 8px;
  padding-left: 16px;
  color: #28293d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  height: 88px;
  resize: none;
  width: 100%;
  border: none;
  font-family: 'Inter', sans-serif;
}

/* Profile */

.profileWrapper {
  flex: 1;
  margin-top: 20px;
  padding: 0 24px;
}

.profileMainSection {
  display: grid;
  gap: 16px;
}
.profileTitle {
  color: #718096;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  margin-bottom: 8px;
}

.profileContainerLink {
  border-radius: 4px;
  background: #f1f8ff;
  padding: 8px;
  display: flex;
  justify-content: space-between;
}

.profileLinkTitle {
  color: #0373fe;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  text-overflow: ellipsis;
  max-width: 330px;
  white-space: nowrap;
  overflow: hidden;
}

.profileBoldTitle {
  color: #28293d;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.profileContainerSubTitle {
  display: flex;
  align-items: center;
}

.profileIcon {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.profileIcon svg {
  height: 18px;
  width: 18px;
}
.profileComment {
  color: #718096;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  display: flex;
  padding: 24px;
  justify-content: space-between;
}
