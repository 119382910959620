.table {
  border: none !important;
  overflow: visible !important;
  user-select: none;
}

.table tbody td {
  border-bottom: none !important;
  padding: 8px 16px !important;
}

.table tbody tr {
  border-bottom: none;
}

.table thead {
  border-bottom: 0.5px solid #cbd5e0;
}
.table thead td {
  padding: 8px 16px !important;
}

.tableContainer {
  overflow: inherit;
  border: none;
}

.closeIcon {
  border-bottom: none !important;
}

.deleteSubTitle {
  color: #394253;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
}

.deleteSubTitleBold {
  font-weight: 700;
}

.drawerWrapper {
  z-index: 1000 !important;
  height: calc(100%)
}
