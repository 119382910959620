.button {
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    color: #718096;
    height: 44px;
    position: relative;
    border-radius: 4px;
    padding: 10px 16px;
}

.iconButton {
    position: absolute;
    right: 13px;
    top: 8px;
}

.formatActive {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #718096;
}

.format {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #0373FE;
}