.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addNewText {
  color: #28293d;
  background-color: #f2f2f5;
  max-width: 120px;
}
.addNewText:hover {
  color: #28293d;
  background-color: transparent;
  border: 1px solid #28293d;
}

.disableClick {
  pointer-events: none;
}

.wrapper {
  user-select: none;
}
