.chartHoliday {
  background-color: var(--color-blue-100);
  font-size: 12px;
}

.arrowBack {
  background-color: var(--bg-light);
  font-size: 12px;
}

.textAreaHolidays {
  background: var(--color-text-light200);
  border-radius: 4px;
  padding: 8px;
  border: none;
  width: 363px;
  height: 92px;
  resize: none;
}

.holidayForm {
  display: flex;
  flex: 1;
  padding: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  /* border-bottom: 1px solid var(--color-black300); */
  /* display: flex; */
  /* flex: 1; */
  /* overflow: scroll; */
}
.holidayForm::-webkit-scrollbar {
  display: none;
}

.buttonAddHoliday {
  width: 100%;
  margin: 16px 0px;
  border: 1px solid var(--color-black700);
  color: var(--color-black700);
}

.buttonAddHoliday svg path {
  stroke: var(--color-black700);
}

.buttonAddHoliday:hover svg path {
  stroke: var(--color-blue-100);
}

.buttonAddHoliday:hover {
  background-color: var(--color-black700);
  color: var(--color-blue-100);
}

.buttonAddHoliday:global(.active),
.active,
.active:focus {
  color: var(--color-primary);
  font-weight: 700;
  background-color: var(--color-blue-100);
}

.dateHoliday {
  background: var(--color-text-light200);
  border-radius: 4px;
  justify-content: left;
  padding: 8px 16px;
}
.card {
  padding-bottom: 16px;
  border-bottom: 1px solid #cbd5e0;
  margin-bottom: 32px;
}
