.root {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;

  position: relative;
  display: inline-flex;
  align-items: center;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  padding: 5px;
  background-color: transparent;
}

.label {
  font-size: 0.875rem;
}

.icon {
  user-select: none;
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.input {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.placementStart {
  flex-direction: row-reverse;
}

.placementStart .label {
  margin-right: 0.75rem;
}

.placementTop {
  flex-direction: column-reverse;
}

.placementTop .label {
  margin-bottom: 0.75rem;
}

.placementBottom {
  flex-direction: column;
}

.placementBottom .label {
  margin-top: 0.75rem;
}

.placementEnd {
  flex-direction: row;
}

.placementEnd .label {
  margin-left: 0.75rem;
}

.disabled {
  pointer-events: none;
  cursor: default;
  color: rgba(0, 0, 0, 0.26);
}
