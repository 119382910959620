.root {
    display: grid;
    padding: 10px 24px;
}

.root .head {
    display: grid;
    grid-gap: 24px;
    align-items: center;

}

.root .head > li {
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    color: var(--color-black500);
    font-size: 14px;
    line-height: 24px;
}

.root .head > li:first-child > span {
    cursor: pointer;
}

.root .head > li:last-child, .root .head > li:nth-child(7) {
    border-top: 1px solid #EBEBF0;
    border-bottom: 1px solid #EBEBF0;
    padding: 16px 0;
    grid-template-columns: 1fr;
    grid-gap: 16px;
}

.root .head > li:last-child {
    border: none;
    padding: 0 0 16px;
}

.root .head > li:nth-child(7) > span {
    font-size: 16px;

}

.root .head > li:last-child > .active, .root .head > li:nth-child(7) .active {
    color: var(--color-black500)
}

.root .head > .liList > span {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
}

.root .head > .liList > span > span {
    transition: .3s;
    border: 1px solid #718096;
    border-radius: 8px;
    padding: 4px 8px;
    cursor: pointer;
    line-height: 18px;
    font-size: 12px;
    color: #718096;
    font-weight: 500;
}

.root .head > .liList > span .activePending {
    border: 1px solid #0373FE;
    background: #F1F8FF;
    color: #0373FE;
}

.activePending {
    padding: 2px 14px;
    border-radius: 8px;
    background: #F1F8FF;
    color: #0373FE;
}
.activeApproved {
    padding: 2px 14px;
    border-radius: 8px;
    background: #E6F7F6;
    color: #018782;
}
.activeDeclined {
    padding: 2px 14px;
    border-radius: 8px;
    background: #FAE9EF;
    color: #D52F6D;
}

.root .head > .liList > span .activeApproved {
    border: 1px solid #018782;
    background: #E6F7F6;
    color: #018782;
}

.root .head > .liList > span .activeDeclined {
    border: 1px solid #D52F6D;
    background: #FAE9EF;
    color: #D52F6D;
}

.root .head > li > span {
    font-size: 13px;
    line-height: 24px;
    color: var(--color-black700);
    font-feature-settings: 'ss02' on, 'clig' off, 'liga' off;
}

.root .head > li > span > img {
    width: 24px;
    height: 24px;
    border-radius: 4px;
}

.root .head > li:first-child > span {
    display: flex;
    grid-gap: 5px;
    align-items: center;

}
.blockHistory{
    overflow-x: scroll!important;
    display: grid;
    gap: .5rem;
    max-height: 250px;
}
.root .history {
    display: grid;
    gap: 1rem;
    width: 100%;
    border: 1px solid #F2F2F5;
    border-radius: 8px;
    padding: 16px;
    font-family: 'Inter', sans-serif;
}
.root .history .history_left{
    display: flex;
    justify-content: space-between;
    gap: .5rem

}
.root .head  > li .historyEmpty{
    font-size: 16px;
}
.history_rightTime{
    font-size: 12px;
    line-height: 18px;
    color: #A0AEC0;
}
.root .history .history_right {
    display: flex;
    gap: .5rem;

}
.root .history .history_left > span:first-child{
    display: flex;
    align-items: center;
    gap: 5px;
    color:#28293D;
    font-size: 14px;
}

.root .history .history_left > span:first-child > img {
    width: 24px;
    height: 24px;
    border-radius: 4px;
}
