.wrapper {
    padding: 0 24px;
}

.row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 8px;
}

.textColor {
    color: #718096;
}

.containerText {
    width: 20%;
    font-size: 14px;
    line-height: 24px;
    color: #718096;
    padding: 12px 0;
}

.containerRow {
    display: flex;
    grid-gap: 10px;
    flex-direction: row;
    align-items: center;
}

.monospace {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #28293D;

}

.divide {
    border-bottom-width: 1px;
    border-bottom-color: #ebebf0;
    border-bottom-style: solid;
    width: 100%;
    margin: 12px 0;
}

.mBottom {
    margin-bottom: 32px;
}

.mTop {
    margin-top: 24px;
}

.relative {
    position: relative;
}

.menu {
    border-radius: 8px;
    width: 120px !important;
    margin-top: 7px;
    padding: 6px 0;
    z-index: 2000 !important;
    top: 30px;
}

.status {
    border: 1px solid #718096;
    border-radius: 8px;
    color: #718096;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    padding: 4px 8px;
    transition: .3s;
}

.activeResolved {
    color: var(--color-green600);
    background-color: var(--color-green100);
    border: 1px solid var(--color-green600);
}

.activePending {
    border: 1px solid var(--color-primary);
    background-color: var(--color-blue-100);
    color: var(--color-primary);
}

.activeInProgress{
    border: 1px solid var(--color-yellow500);
    color: var(--color-yellow500);
    background-color: var(--color-yellow100);
}
