.root {
  position: fixed;
  top: 50%;
  left: 50%;

  padding: 24px;
  width: 100vw;
  max-width: 450px;
  max-height: 90vh;
  border-radius: 16px;
  background-color: #ffffff;

  overflow: auto;
  z-index: 1040;
  transform: translate(-50%, -50%);
}
.rootFull{
  max-width: 100%
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-backdrop);
  background-color: transparent;
  transition: 0.2s;
  opacity: 0.5;
}

.transition {
  animation: 0.3s;
  background: #28293D80;
  animation-fill-mode: forwards;
}

@keyframes animationBackground {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: #28293d;
  }
}
