.wrapper {
    border: 1px solid var(--color-text-light400);
    border-radius: 16px;
    transition: 0.2s;
}

.wrapper:hover {
    cursor: pointer;
    background-color: var(--bg-light);
}

.statusWrapper {
    display: flex;
    align-items: flex-end;
}

.stageCardWrapper {
    width: 100px;
}

.accessWrapper {
    padding: 4px 8px;
    background-color: var(--color-text-light200);
    border-radius: 8px;
    display: inline-block;
    width: auto;
    height: auto;
}

.commentWrapper {
    border: 1px solid var(--color-text-light400);
    border-radius: 8px;
    background-color: var(--bg-light);
    padding: 8px 16px;
}
