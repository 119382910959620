.container {
    display: grid;
    height: 100%;
    align-content: space-between;
    padding: 12px 24px;
}
.container_form{
    display: grid;
    gap: 1.5rem
}
.container .selectInput::placeholder{
    color: #A0AEC0;
    font-size: 16px;
}
.container .timeSelect{
    display: flex;
    justify-content: center;
    appearance: none;
    width: 86px;
    background: #FAFAFC;
    border: none;
    outline: none;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #28293D;
    cursor: pointer;
}

.container .buttonGroup{
    border-top: 1px solid #CBD5E0;
    padding-top: 2rem;
}



.container > button{
    margin-bottom: 1rem;
}
