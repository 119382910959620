.root {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  list-style-type: none;
}

.root li {
  list-style-type: none;
}

.root li::marker,
.root li::before {
  display: none;
}

.root li a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  min-width: 25px;
  font-size: 13px;
  font-weight: 500;
  color: var(--color-black400);
  height: 25px;
  border: 1px solid transparent;
  border-radius: 8px;
}

.root li:global(.previous) {
  margin-right: 0.25rem;
}

.root li:global(.next) {
  margin-left: 0.25rem;
}

.root li a:hover {
  color: var(--color-black500);
}

.root li:global(.selected) a {
  color: var(--color-black500);
  border-color: var(--color-blue-300);
}
