.rootTableRow td {
  padding: 8px 16px !important;
}

.rootTableRow td:nth-child(2) {
  padding: 8px 0 !important;
}

.holidayRow:hover {
  transition: 0.2s;
}

.holidayRow {
  border-bottom: 1px solid #f2f2f5 !important;
}

.contentMaxWidth {
  padding: 8px 0 !important;
  vertical-align: middle;
}

.contentMaxWidth div p {
  white-space: break-spaces;
  /*max-width: 100px;*/
  word-wrap: break-word;
}

.editIcon {
  border-radius: 6px;
  background-color: white;
  font-size: 14px;
  height: 24px;
  width: 24px;
}
.trashIcon {
  background-color: var(--color-pink100);
  border-radius: 6px;
  font-size: 12px;
  height: 24px;
  width: 24px;
}

.stylingPlusButton {
  background-color: var(--color-blue-100);
  border-radius: 6px;
  height: 24px;
  width: 24px;
  padding: 0;
}

.stylingPlusButton path {
  height: 10px;
  width: 10px;
}
