.navContainer {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr;
    grid-gap: 10px;
    margin-bottom: 17px;
    border-bottom: 1px solid #edf2f7;
    height: 38px;
}

.navContainer > div:first-child {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    align-items: center;


}

.navContainer > div:last-child {
    margin-bottom: 18px;
    border-left: 1px solid #edf2f7;
    padding-left: 5px;
}

.tabItemBadge {
    margin-left: 8px;
}

.tabItem {
    flex-shrink: 0;
    padding: 0.375rem 0;
    width: 8rem;
    min-width: 120px;
    border-radius: 0;
    border-bottom: 3px solid transparent;
    color: #28293d;
    font-weight: 300;
    white-space: nowrap;
}

.container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.leftSide {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    margin-right: 16px;
}

.rightSide {
}

.customInput {
    width: 200px;
}

.customInputShowBy {
    width: 200px;
}

.customInputShowBy:nth-child(1) div {
    color: #718096;
    font-size: 14px;
}

.font500 {
    font-weight: 500;
}

.tabItemActive {
    border-bottom-color: var(--color-black700);
}

.containerBtns {
    min-height: 36px;
}

.chip {
    padding: 4px 8px !important;
    height: min-content !important;
    min-width: min-content !important;
}

.chip p {
    font-size: 12px !important;
}
