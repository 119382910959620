.wrapper {
  flex: 1;
  user-select: none;
}

.container {
  display: grid;
  gap: 16px;
}

/* Modal */

.overlay {
  z-index: 1050;
}

.modal {
  z-index: 1051;
  max-width: 415px;
  padding: 24px 0 0 !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.title {
  color: #28293d;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.btnIcon {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: #fafafc;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}
.btnIcon:hover {
  cursor: pointer;
  background-color: #fae9ef;
  transition: 0.2s;
}
.btnIcon:hover svg {
  fill: #d52f6d;
  transition: 0.2s;
}

.btnIcon svg {
  rotate: 45deg;
  fill: #718096;
  height: 20px;
  width: 20px;
}

/* Modal */
