.overlay {
  z-index: 1050;
}

.modal {
  z-index: 1051;
  max-width: 860px;
  max-height: 622px;
  height: 100%;

  overflow: initial;
  padding: 0;
  display: flex;
  flex: 1;
  border: 1px solid #f2f2f5;
  user-select: none;
}

.wrapperListBtn {
  width: 100%;
  padding: 0 24px;
}
.listBtn {
  color: #28293d;
  background-color: #f2f2f5;
  transition: 0.2s;
}

.listBtn:hover path {
  transition: 0.2s;
  fill: white;
}

.copyIcon path {
  stroke: #718096;
  stroke-width: 2px;
}

.grid {
  overflow-y: auto;
  padding-top: 16px;
  height: 100%;
}
.grid :last-child {
  border: none;
}

.cardTitle {
  color: #718096;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
}

.textAreaTitle {
  color: #28293d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 4px;
}

.comment {
  border-radius: 4px;
  background: #fafafc;
  padding: 8px;
  padding-left: 16px;
  color: #28293d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  height: 88px;
  resize: none;
  width: 100%;
  border: none;

  font-family: Inter;
}

.comment:focus {
  outline: none;
}

/* Profile */

.profileWrapper {
  flex: 1;
  margin-top: -32px;
}

.profileIcon {
  margin: 5px;
  height: 70px;
  width: 70px;
}

.wrapperProfileIcon {
  display: flex;
  justify-content: center;
}

.profileInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 24px;
}

.profileUserName {
  color: #28293d;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 4px;
}

.profileRole {
  color: #718096;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
}

.profileEmail {
  color: #0373fe;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}

.profileTime {
  color: #718096;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 16px 0;
}

.profileComment {
  color: #718096;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

/* Card */
.card {
  margin: 0 24px;
  flex: 1;
  padding: 0 8px 8px;
  cursor: pointer;
  border-bottom: 1px solid #f2f2f5;
}

.card:hover {
  background-color: #fafafc;
}

.cardHeader {
  display: flex;
  align-items: center;
}

.cardIcon {
  margin-top: -3px;
  width: 16px;
  height: 16px;
}

.cardIcon path {
  height: 14px;
  width: 14px;
  fill: #718096;
}

.contactName {
  color: #28293d;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 8px;
}

.contactTime {
  color: #a0aec0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.contactRole {
  color: #a0aec0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 4px 0;
}

.contactEmail {
  color: #a0aec0;
  font-size: 14px;
  text-decoration-line: underline;
  user-select: text;
}

.addContactsComponent {
  display: grid;
  gap: 16px;
  overflow-y: scroll;
  padding: 10px 24px;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  display: flex;
  padding: 24px;
  justify-content: space-between;
}

.container {
  padding: 16px 24px;
  flex: 1;
}
