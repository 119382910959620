.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.buttonHolidays {
  background: var(--color-black700);
  border-radius: 12px;
  color: var(--color-text-light100);
  padding: 10px 16px;
  gap: 8px;
}

.buttonHolidays:hover {
  opacity: 0.1;
}

.buttonAdd {
  gap: 5px;
}
