.img {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  object-fit: cover;
}

.img:hover {
  cursor: pointer;
}

.z-0 {
  z-index: 1;
}
.z-1 {
  z-index: 2;
  margin-left: -4px;
}
.z-2 {
  z-index: 3;
  margin-left: -4px;
}

.relative {
  position: relative;
}

.containerOptions {
  position: absolute;
  /* width: 100%; */
  display: none;
  padding: 8px;
  z-index: 10;
  border: 1px solid #fafafc;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 12px 42px -4px rgba(24, 39, 75, 0.12),
    0 8px 18px -6px rgba(24, 39, 75, 0.12);
  transform: translateX(-100%) translateY(calc(-50% + 12px));
  top: 0;
}

.showOptions {
  display: flex;
  animation: animationBackground 0.2s forwards;

}
@keyframes animationBackground {
  0% {
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1;
  }
}

.left-0 {
  left: -10px;
}
.left-1 {
  left: -14px;
}

.left-2 {
  left: -14px;
}

.arrow {
  position: absolute;
  right: -9px;
  top: calc(50% - 7px);
}
