.btnBase {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
.icon {
  height: 20px;
  width: 16px;
}

.leftSideHeader {
  display: flex;
  align-items: center;
}

.arrowBack {
  background-color: #ffffff;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentSize {
  width: 220px;
}
.section {
  margin-top: 14px;
}
.section :first-child {
  margin-bottom: 8px;
}
.section :last-child {
  padding-bottom: 8px;
  border-bottom: 1px solid #ebebf0;
}

.containerBtn {
  margin: 14px 0;
}

.containerBtn button {
  color: #28293d;
  border: 1px solid #28293d;
}

.containerItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  margin: 4px 0;
}

.containerSelect {
  height: 22px;
  margin: 8px 0;
  border-left: 2px solid #4d9cfe;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 6px;
  cursor: pointer;
}

.containerSelect p {
  font-weight: 700;
  color: #4d9cfe;
}

.footerMenu {
  width: 220px;
  transform: translateX(50%);
  margin-top: 4px;
  border-radius: 8px;
  border: none;
  padding: 0;
}

.containerSelectItem {
  display: flex;
  height: 40px;
  align-items: center;
}
.containerSelectItem:hover {
  background-color: #fafafc;
  cursor: pointer;
}

.containerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
}
.containerIcon svg {
  width: 20px;
  height: 22px;
}

.colorBlack path {
  fill: #394253;
}

.colorGray path {
  fill: #718096;
}

.borderBottom {
  border-bottom: 1px solid #f2f2f5;
}

.deleteAccountModal {
  max-width: 440px;
  border: 1px solid #ebebf0;
  border-radius: 8px;
  z-index: 1101;
}

.archiveAccountModal {
  max-width: 440px;
  border: 1px solid #ebebf0;
  border-radius: 8px;
  z-index: 1101;
}

.overlay {
  z-index: 1100;
}
