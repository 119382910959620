.card {
  font-size: 12px;
  line-height: 18px;
  padding: 4px 8px;
  border-radius: 8px;
  margin-right: 4px;
}

.design {
  color: #551bce;
  background-color: #f0eafc;
}

.development {
  color: #018782;
  background-color: #e6f7f6;
}

.qa {
  color: #d8a12c;
  background-color: #fbf5e9;
}

.front-end {
  color: #018782;
  background-color: #e6f7f6;
}

.back-end {
  color: #018782;
  background-color: #e6f7f6;
}

.pm {
  color: #1958e3;
  background-color: #f1f8ff;
}

.pending {
  color: #1958e3;
  background-color: #f1f8ff;
}

.in-progress {
  color: #d8a12c;
  background-color: #fbf5e9;
}

.done {
  color: #018782;
  background-color: #e6f7f6;
}

.on-hold {
  color: #718096;
  background-color: #fafafc;
}

.finished {
  color: #018782;
  background-color: #e6f7f6;
}

.branding {
  color: #1958e3;
  background-color: #f1f8ff;
}
