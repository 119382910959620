.wrapper {
  padding: var(--main-padding-y) var(--main-padding-x);
}
.wrapper_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.documentsWrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 40px;
}

.documents {
  width: 754px;
}

.documentsTopBar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-top: 24px;
}

.searchWrapper {
  display: flex;
  justify-content: flex-end;
}

.documentsContainer {
  padding: 16px;
  margin-top: 24px;
  width: 100%;
  height: calc(100vh - 300px);
  border-radius: 16px;
  border: 1px solid var(--color-text-light300);
}

.employeeDocumentWrapper {
  height: 100%;
  overflow: auto;
}

.selectorWrapper {
  margin-left: 16px;
  width: 170px;
}

.selectorsWrapper {
  display: flex;
  flex-direction: row;
}

.selector {
  height: 40px;
}

.valueStyle {
  color: var(--color-black500);
  font-size: 14px !important;
}

.noContentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.noContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buttonAdd {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}
/*.buttonAdd > svg{*/
/*    transform: rotate(180deg);*/
/*}*/

.customInputShowBy:nth-child(1) div {
  color: #718096;
  font-size: 14px;
}
