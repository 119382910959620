.root {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: inherit;
  z-index: 2;
}

.track {
  width: 30px;
  height: 18px;
  opacity: 0.4;
  background-color: var(--color-black700);
  border-radius: 18px;
  z-index: -1;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.thumb {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 16px;
  height: 16px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);;
  z-index: 1;
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.medium .track {
  width: 38px;
  height: 22px;
}

.medium .thumb {
  width: 20px;
  height: 20px;
}

.input:checked ~ .track {
  opacity: 1;
}

.input:checked ~ .thumb {
  transform: translateX(12px);
}

.medium .input:checked ~ .thumb {
  transform: translateX(16px);
}
