.departmentsList {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
}

.departmentsListRows{
    display: grid;
    align-items: center;
    grid-template-columns: auto 2fr auto 0fr;
    grid-gap: 10px;
    width: 100%;
}
.departmentsListRows > svg:first-child{
    transform: rotate(180deg);
}
.departmentsListRows > svg {
    cursor: pointer;
}

.departmentsList::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.tabItem {
    flex-shrink: 0;
    padding: 0.375rem 0;
    min-width: 120px;
    border-radius: 0;
    border-bottom: 3px solid transparent;

    white-space: nowrap;
}

.tabItemContainer {
    display: flex;
    align-items: center;
    padding: 0 4px;
}

.tabItemBadge {
    margin-left: 0.5rem;
}

.tabItemActive {
    border-bottom-color: var(--color-black700);
}

.searchWrapper {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 16px;
    border-left: 1px solid #edf2f7;
    padding-left: 1rem;
}
