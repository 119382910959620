.backDrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1000;
}

.root {
  position: absolute;
  min-width: 160px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #EBEDF0;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(40, 41, 61, 0.08), 0 20px 32px rgba(96, 97, 112, 0.24);
  z-index: 1010;
}