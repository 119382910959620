.table {
  border-bottom: 0px !important;
}
.theadRow {
  border-bottom: 1px solid #f2f2f5;
}

.theadRow th {
  padding: 10px !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-bottom: 0px !important;
}
