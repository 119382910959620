.rootTableRowTD:hover {
  background-color: #f2f2f5;
  transition: 0.2s;
}

.rootTableRowTD {
  vertical-align: top;
  transition: all 0.2s;
}

.rootTableRowTD td {
  transition: all 0.2s;
  padding: 16px 0 !important;
  border-top: 1px solid #f2f2f5 !important;
  border-bottom: 0 !important;
  cursor: pointer;
}

.rootCell {
  gap: 0 !important;
  padding: 0 !important;
  /* align-items: ; */
  align-self: self-start;
}

.rootTableRow th {
  padding: 7px 12px !important;
  border: 0 !important;
}

.dateText {
  color: #394253;
  line-height: 24px;
  margin-bottom: 4px;
}

.containerDateCell {
  margin-left: 8px;
}

.sumText {
  color: #d52f6d;
  margin-bottom: 4px;
  line-height: 24px;
}

.accountText {
  color: #28293d;
  margin-bottom: 4px;
  line-height: 24px;
  font-weight: 500;
}
.mb4 {
  margin-bottom: 4px;
}

.compact {
  height: 40px;
  transition: 0.2s;
  /*overflow: hidden;*/
}

.compactOn {
  height: 0;
  transition: 0.2s;
  overflow: hidden;
}

.checkbox {
  margin-top: 2px;
  margin-right: 12px !important;
  align-items: flex-start !important;
  padding: 0 !important;
}

.alignStart {
  align-items: flex-start;
}
.checkbox svg {
  height: 16px !important;
  width: 16px !important;
}

.row {
  display: flex;
  align-items: center;
}

.mL {
  margin-left: 32px;
}

.table {
  border: none;
  overflow: visible !important;
}

.smallImage {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 4px;
}

.projectName {
  color: #28293d;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: #a0aec0;
  border-radius: 4px;
  margin: 0 4px;
}

.projectDate {
  margin-left: 31px;
  color: #718096;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 4px;
}

.cardAvatar {
  display: flex;
  align-items: center;
  color: #28293d;
  font-size: 14px;
}

.cardAvatar img {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 6px;
  object-fit: cover;
}

.cardAvatar p {
  color: #28293d;
}

.card {
  font-size: 12px;
  line-height: 18px;
  padding: 4px 8px;
  border-radius: 8px;
  margin-right: 4px;
  color: #28293d;
  background-color: #fafafc;
}

.rootCellTeam {
  gap: 0 !important;
  padding: 0 !important;
  /* align-items: ; */
  align-self: self-start;
  width: 120px;
}

.tbody {
  overflow: visible;
}

.emojiWrapper {
  width: 24px;
  height: 24px;
  background-color: #015bcb;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperStageCards {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
  gap: 8px;
}

.stageCard {
  margin: 0;
}

.containerManagers {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.projectName {
  margin-left: 4px;
}
