/* Drawer */

.rootDrawer {
  height: 100% !important;
  margin-top: 0px !important;
  z-index: 1;
}

.iconDrawer {
  background-color: #f1f8ff;
}
/* Drawer */

.root {
  display: flex;
  flex-direction: column;
  padding: 16px var(--main-padding-x);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.headerContainerButtons {
  display: flex;
  height: 40px;
}

.editProjectBtn {
  background-color: #f2f2f5;
  color: #28293d;
  transition: 0.2s;
  font-weight: 500;
  margin-right: 16px;
}
.editProjectBtn p {
  margin-left: 8px;
}
.editProjectBtn:hover path {
  fill: white;
  transition: 0.2s;
}
.editProjectBtn path {
  fill: #28293d;
}

.resourcesBtn {
  border-color: #28293d;
  color: #28293d;
  transition: 0.2s;
  font-weight: 500;
}
.resourcesBtn p {
  margin-left: 8px;
}
.resourcesBtn:hover {
  transition: 0.2s;
  opacity: 0.5;
}
.resourcesBtn svg {
  fill: none;
}

.mainSection {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.flex {
  flex: 1;
}
