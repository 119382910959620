.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.closeIcon {
  background-color: #fafafc;
  height: 32px;
  width: 32px;
  border-radius: 8px;
}

.closeIcon svg {
  rotate: 45deg;
  width: 20px;
  height: 20px;
}
.closeIcon path {
  stroke: #718096;
  stroke-width: 2px;
}

.outlinedBtn {
  border: 1px solid #28293d;
  color: #28293d;
  margin-right: 16px;
}

.resetBtn {
  background-color: #f2f2f5;
  color: #28293d;
}
.resetBtn:hover {
  background-color: white;
  color: #28293d;
  border: 1px solid #28293d;
}

.baseBtn {
  width: 115px;
}

.positionButtons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.overlay {
  z-index: 1050;
}

.saveModal {
  z-index: 1051;
  max-width: 420px;
}

.saveModalContainerButtons {
  display: flex;
  justify-content: space-between;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.flexSizeInput {
  flex: 1;
}
