.select {
  display: flex;
  justify-content: flex-end;
  height: 24px;
  width: 150px;
}

.select:hover {
  cursor: pointer;
}

.selectTitle {
  color: #a0aec0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 8px;
}

.relative {
  position: relative;
}
.containerOptions {
  position: absolute;
  width: 100%;
  display: none;
  z-index: 1;
  border: 1px solid #fafafc;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12),
    0px 8px 18px -6px rgba(24, 39, 75, 0.12);
}

.showOptions {
  display: block;
  animation: animationBackground 0.2s forwards;
}
@keyframes animationBackground {
  0% {
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

.option {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.option:hover {
  background-color: #fafafc;
  cursor: pointer;
}

.borderTop {
  border-top: 1px solid #f2f2f5;
}

.smallPadding {
  padding-left: 8px;
  padding-right: 0px;
}
