.header {
  padding: 8px;
  background-color: #fafafc;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
}

.containerCard {
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-right: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.greenBackground {
  background: var(--color-turquoise-100) !important;
  padding: 4px 8px;
  border-radius: 8px;
}

.redBackground {
  background: var(--color-french-wine-100) !important;
  padding: 4px 8px;
  border-radius: 8px;
}

.greenText {
  color: var(--color-turquoise-500);
}

.redText {
  color: var(--color-french-wine-400);
}

.container {
  cursor: pointer;
  width: 242px;
  border-radius: 4px;
  padding: 4px 8px;
  display: grid;
  gap: 4px;
  transition: 0.2s;
  flex-wrap: wrap;
}

.fullWidth {
  width: 100% !important;
}

.row {
  display: flex !important;
  flex-direction: row !important;
}

.container:hover {
  transition: 0.2s;
  background-color: #fafafc;
}

.avatar {
  display: flex;
  align-items: center;
  color: #28293d;
  font-size: 14px;
  padding-right: 4px;
}

.avatar img {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 4px;
  object-fit: cover;
  object-position: center;
}

.search {
  color: #28293d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  appearance: none;
  caret-color: #0373fe;
  background-color: #fafafc;
  width: 100%;
}
.search:focus {
  outline: none;
}
.title {
  color: #a0aec0;
  font-size: 12px;
  font-weight: 400;
  padding: 8px 8px 4px 8px;
  line-height: 18px;
}

.selectableCard {
  padding: 8px;
  cursor: pointer;
}

.selectableCard:hover {
  background-color: #fafafc;
}

.bgGray {
  background-color: #fafafc;
  width: fit-content;
}

.plug {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-size: 14px;
  color: #a0aec0;
}

.main {
  overflow-y: scroll;
  max-height: calc(30px + 40px * 5);
  transition: 0.2s;
}
