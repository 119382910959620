.root {
  display: flex;
  flex-direction: column;
  padding: var(--main-padding-y) var(--main-padding-x);
  align-items: center;
}

.title {
  display: flex;
  margin-bottom: 44px;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 463px;
  text-align: left;
}

.drawerWrapper {
  z-index: 1000 !important;
  height: 100%
}
