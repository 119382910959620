.createTime{
 color: var(--color-black500)
}
.rootCard{
 border-radius: 8px;
 padding: 4px 8px;
 display: flex;
 justify-content: center;
 line-height: 18px;
 max-width: 90px;
}
.projectName{
 display: flex;
 grid-gap: 10px;
 align-items: center;
}
.table {
 min-width: 1000px;
 overflow-x: scroll;
}
.rootTableRowTD, .thead{
 display: grid;
 grid-template-columns: minmax(150px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(300px, 1fr) minmax(50px, 1fr);

}

.sickLeave{
 background: #FAE9EF;
 color: #D52F6D;
}
.oneToOne{
 color: #0373FE;
 background: #F1F8FF;

}
.dayOff{
 background: #FBF5E9;
 color: #E0B457;

}
.approved{
 background: #E6F7F6;
 color: #018782;

}
.rootTableRowTD:hover {
 background-color: #f2f2f5;
 transition: 0.2s;
}

.rootTableRowTD {
 vertical-align: top;
 transition: all 0.2s;
}

.rootTableRowTD td {
 transition: all 0.2s;
 padding: 16px 12px !important;
 border-top: 1px solid #f2f2f5 !important;
 border-bottom: 0 !important;
}

.tableRowCompact {
 transition-delay: 0.2s;
 transition: all 0.2s;
}
.tableRowCompact td {
 padding: 8px 12px 4.5px 12px !important;
 transition: all 0.2s;
}
