.mainInfoWrapper {
  display: flex;
  gap: 24px;
  user-select: text;
}

.mainInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 8px;
}

.userName {
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: 16px;
  font-weight: 700;
  color: #28293d;
  user-select: text;
}
.userName .userBench{
  display: grid;
  justify-items: start;
  gap: 1rem;
}
.userName > div{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}
.userName > div > svg{
  width: 16px;
  height: 16px;
}
.userName > div > svg > path{
  fill: #0373FE;
}
.userName > div:hover{
  background: #F1F8FF;
  border-radius: 6px;
}
.containerRole {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.containerStatuses {
  display: flex;
  gap: 4px;
  margin: 4px 4px 4px 0;
  flex-wrap: wrap;
}

.birthdayContainer {
  display: flex;
  align-items: flex-end;
}

.birthdayContainer svg {
  margin-right: 4px;
  height: 24px;
  width: 24px;
}

.birthdayContainer p {
  color: #718096;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.birthDayTextColor {
  color: var(--color-black500);
}

.containerName {
  display: flex;
  justify-content: space-between;
  margin-bottom: -8px;
}

.socialContainer {
  display: flex;
  gap: 8px;
}
.socialItem {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.userSelectText {
  user-select: text;
}
