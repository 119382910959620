.container {
  padding: 2px 8px;
  transition: 0.2s;
  color: #394253;
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
  line-height: 24px;
  cursor: pointer;
  border-radius: 4px;
  font-feature-settings: 'ss02' on, 'clig' off, 'liga' off;
}

.fullSize {
  width: 100%
}

.container:hover {
  transition: 0.2s;
  background-color: #fafafc;
}

.select {
  border: 1px solid #28293d;
  background-color: white;
  border-radius: 4px;
  width: 100%;
  padding-right: 8px;
  transition: 0.2s;
}

.input {
  border: none;
  border-radius: 4px;
  padding-left: 8px;
  padding-top: 4px;
  width: 100%;
  resize: none;
  color: #28293d;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  transition: 0.2s;
  outline: none;
}
[contenteditable='true'] {
  transition: 0.2s;
  display: inline-block;
}
[contenteditable='true']:focus {
  outline: 0 solid transparent;
  transition: 0.2s;
}
[contenteditable='true']:empty:before {
  content: attr(placeholder);
  color: #718096;
}

.input:focus {
  outline: none;
}
