.icon {
    padding: 0;
    min-width: 0;
    color: var(--color-black400);
}

.icon:hover {
    color: var(--color-black600);
    background: none;
}
