.navContainer {
  display: flex;
  justify-content: space-between;
  overflow: auto;
  grid-gap: 1rem;
  margin-bottom: 12px;
  border-bottom: 1px solid #edf2f7;
}

.thead th {
  padding: 16px !important;
}
.thead th div {
  height: 32px;
}

.clear {
  padding-right: 0 !important;
}

.table {
  padding-bottom: 150px;
  margin-bottom: -150px;
}

.table::-webkit-scrollbar {
  width: 0 !important;
}

.title {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.iconArchive svg {
  height: 15px;
  width: 15px;
}

.iconArchive path {
  fill: #28293d;
  transition: 0.25s;
}
.btn{
  color: black;
}

.btn:hover .iconArchive path {
  transition: 0.25s;
  fill: white;

}
.iconArchive {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
