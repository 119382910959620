.option {
  display: block;
  padding: 6px 8px;
  cursor: pointer;
}

.option:hover {
  background-color: var(--color-gray-200);
}

.container {
  cursor: pointer;
}
