.wrapper {
  height: max-content;
}

.table {
  border-top: 1px solid #f2f2f5;
  border-bottom: 0px !important;
  user-select: none;
  border-collapse: separate;
}

.theadRow th {
  padding: 8px !important;
  border-bottom: 1px solid #f2f2f5 !important;
}

.theadRow th:first-child {
  position: sticky;
  left: 0;
  z-index: 0;
  padding: 8px 16px !important;
  border-right: 1px solid #f2f2f5;
  background-color: white;
}

.theadRow th:last-child {
  position: sticky;
  right: 0;
  z-index: 0;
  background-color: #fafafc;
  color: #28293d;
}

.headCellHover {
  cursor: pointer;
}
