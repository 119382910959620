.equipmentWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  margin-top: 16px;
}

.headerToCenter {
  align-items: center;
}

.drawer {
  height: 100% !important;
  margin-top: 0 !important;
}

.formWrapper {
  flex: 1;
  padding: 0 24px;
  max-height: 100vh;
  overflow-y: auto;
}

.submitFormButtonWrapper {
  padding: 24px;
}

.disableClick {
  pointer-events: none;
}
