.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: center;
  margin-bottom: 56px;
  user-select: none;
}

.title {
  color: #28293d;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
}

.text {
  width: 432px;
  text-align: center;
  color: #394253;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
}

.link {
  color: #0373fe;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-decoration-line: underline;
  cursor: pointer;
}
