.wrapper {
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: max-content;
}

.paddingTop {
  padding-top: 32px;
}

.topContentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
}

.buttonText {
  color: var(--color-black700);
  margin-left: 16px;
  transition: 0.3s;
}
.buttonText:hover > svg > path {
  fill: #fff;
  transition: 0.3s;
}

.btnEdit:hover > svg > path {
  fill: #718096 !important;
}

.iconStyle {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.buttonDocument {
  color: var(--color-black700);
  /* margin-left: 16px; */
  transition: 0.3s;
}

.buttonDocument svg path {
  stroke: #a0aec0;
}

.buttonDocument:hover > svg > path {
  transition: 0.3s;
  stroke: white;
}

.iconDocumentStyle {
  margin-right: 8px;
  fill: transparent !important;
}

.profileWrapper {
  width: 100%;
  display: flex;
  gap: 16px;
}

.profileBreadCrumbWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 1359px) {
  .profileWrapper {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 1100px) {
  .profileWrapper {
    display: grid;
  }
}

@media (max-width: 950px) {
  .topContentWrapper {
    justify-content: space-between;
    align-items: flex-start;
  }
  .profileWrapperRight {
    display: grid;
    grid-gap: 10px;
  }
}
