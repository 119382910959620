.container {
  margin-bottom: 20px;
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
}

.rootResetBtn {
  margin-left: 16px;
  padding: 2px 8px;
  border-radius: 8px;
  background-color: #fae9ef;
  color: #d52f6d !important;
}
.rootSelectedBtn {
  padding: 2px 6px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  margin-left: 8px;
}

.rootViewBtn {
  border-radius: 12px;
}

.rootViewBtn {
  color: #28293d;
}

.rootSelectedBtn {
  margin: 8px 8px 8px 0px;
}
.rootSelectedBtn:hover {
  background-color: transparent;
}

.rootSelectedBtn p {
  color: #28293d;
  margin-right: 7px;
}

.rootSelectedBtn svg path {
  fill: #a0aec0;
}
.rootSelectedBtn svg {
  rotate: 45deg;
}

.rootResetBtn {
  margin-right: 8px;
}
.rootResetBtn:hover {
  background-color: transparent;
}

.m {
  margin-left: 16px;
}

.rootFilterBtn {
  border-radius: 4px;
}
.rootFilterBtn svg path {
  fill: #718096;
}

.rootFilterBtn:hover svg path {
  fill: white;
}

.rootFilterBtn:hover p {
  color: white;
}

.rootFilterBtn:hover .dot {
  background-color: white;
}

.rootFilterBtn p {
  color: #718096;
  font-weight: 500;
  margin-left: 9px;
}

.dot {
  background-color: #0373fe;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-left: 2px;
}

.leftSide {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.rightSize {
  flex-wrap: nowrap;
  display: flex;
  align-items: flex-start;
  height: 100%;
}

.mRight16 {
  margin-right: 16px;
}
.breakword {
  /* text-overflow; */
  white-space: nowrap;
  /* overflow-wrap; */
}
