.cardFilter {
  height: 34px;
  background-color: white;
  border-bottom: 1px solid #f2f2f5;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.carfFilterInfo {
  display: flex;
  align-items: center;
}
.carfFilterAdditionalInfo {
  align-items: center;
  opacity: 0;
  transition: 0.2s;
}
.carfFilterAdditionalInfoActive {
  opacity: 1;
  transition: 0.2s;
}

.maxHeight {
  max-height: 204px;
  overflow-y: scroll;
}

.iconTrash path {
  fill: #718096;
}
.iconTrash:hover path {
  fill: #d52f6d;
}

.iconPencil:hover path {
  fill: #0373fe;
}

.iconPencil path {
  fill: #718096;
}

.additionalInfoContainer {
  display: flex;
}

.divide {
  margin: 0 16px;
  height: 14px;
  width: 1px;
  background-color: #cbd5e0;
}

.containerIcons {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.icon {
  height: 16px;
  width: 16px;
}

.containerIcon {
  width: 48px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #718096;
}

.footerCard {
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.footerCardTitle {
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #0373fe;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.emptyFilter {
  box-sizing: border-box;
  padding-top: 16px;
  height: 56px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #718096;
  padding-left: 8px;
}

.footerCard:hover,
.cardFilter:hover {
  transition: 0.2s;
  background-color: #fafafc;
}
.plusIcon {
  box-sizing: border-box;
  width: 44px;
  height: 40px;
  display: flex;
  padding-left: 6px;
  justify-content: center;
  align-items: center;
}

.plusIcon svg {
  width: 20px;
  height: 20px;
  fill: #0373fe;
}
