.root {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  padding: 2.5rem 1.25rem;
}

.content {
  width: 100vw;
  max-width: 400px;
  padding: 32px 20px;
  border-radius: 24px;
  box-shadow: 0 15px 30px rgba(0, 56, 178, 0.1);
}

.divider {
  margin: 1.25rem 0;
}
