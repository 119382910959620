.z-3 {
  z-index: 4;
  margin-left: -4px;
}

.card {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card:hover {
  cursor: pointer;
}

.innerCard {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafc;
}

.innerCard p {
  color: #28293d;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
}

.relative {
  position: relative;
}

.containerOptions {
  position: absolute;
  /* width: 100%; */
  display: none;
  padding: 8px;
  z-index: 10;
  border: 1px solid #fafafc;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 12px 42px -4px rgba(24, 39, 75, 0.12),
    0 8px 18px -6px rgba(24, 39, 75, 0.12);
  transform: translateX(-25%);
  top: 32px;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
}

.showOptions {
  display: flex;
  animation: animationBackground 0.2s ;
}
@keyframes animationBackground {
  0% {
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1;
  }
}

.divide {
  margin: 8px 0;
  height: 1px;
  background-color: #f2f2f5;
}

.row {
  display: flex;
}

.arrow {
  position: absolute;
  /* right: -9px; */
  top: -14px;
  right: 30px;
  transform: rotate(-90deg);
}

.activeBc {
  background-color: #0373fe;
}
