.root {
  display: flex;
}

.column {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.row {
  flex-shrink: 0;
}

.space1 {
  gap: 8px
}

.space2 {
  gap: 16px
}

.space3 {
  gap: 24px
}

.space4 {
  gap: 32px
}

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyAround {
  justify-content: space-around;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyCenter {
  justify-content: center;
}

.fullWidth {
  width: 100%;
  flex-grow: 1;
}
