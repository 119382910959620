.cardRoot {
    display: flex;
    width: 100%;
    grid-gap: 45px;
    margin-top: 2rem;
}

.cardLoader {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 90vh;
    align-items: center;
}

.cardRoot .cardTableLeave .birthdayInfo {
    font-size: 14px;
    line-height: 24px;
    width: 100px;
    text-align: right;
    color: #0373FE;
    font-weight: 500;
}

.cardRoot .cardTableLeave .birthdayInfo .birthdayDay {
    color: #D52F6D;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 5px;
}

.cardRoot .cardTableLeave .cardTableBirthdayWait {
    display: grid;
    grid-gap: 2rem;
    justify-items: center;
    margin-top: 1rem;
}

.cardRoot .cardTableLeave .cardTitle > p {
    color: #0373FE;
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
}

.cardRoot .cardsRight {
    display: grid;
    width: 100%;
    grid-gap: 2rem;
    height: max-content;
}

.cardRoot .cardLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
}

.cardRoot .cardLeftRevers {
    justify-content: flex-end;
    flex-direction: column-reverse;
}

.cardRoot .cards, .cardRoot .cardTable, .cardRoot .cardTableLeave {
    border: 1px solid var(--color-text-light400);
    padding: 16px;
    border-radius: 16px;
    width: 100%;
    height: max-content;
    font-family: 'Inter', sans-serif;
}

.card {
    display: grid;
    align-items: start;
    padding: 0 0 14px;

}


.card .cardTitle {
    font-size: 20px;
    font-weight: 700;
    color: var(--color-black700);
    line-height: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.cards .card .cardSubtitle {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    grid-gap: 16px;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    color: #0373FE;
    padding: 10px 0 0;
}

.cards .card:nth-child(2) .cardSubtitle {
    color: #018782;
}

.cardSecond {
    display: grid;
    grid-gap: 2rem;
    width: 100%;
    height: max-content;
}

.cards .card .cardSubtitle:last-child > span {
    color: #018782;
}

/*.cardSecond:nth-child(2) > .cards:first-child {*/
/*    height: 100%;*/
/*}*/

.cards .card .cardSubtitle > span {
    display: flex;
    grid-gap: 10px;
    align-items: flex-end;
}

.cards .card .cardSubtitle > span > p {
    color: #718096;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    padding-bottom: 10px;
}

.cardTableInnerWait {
    display: grid;
    justify-items: center;
    grid-gap: 1rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: var(--color-black700)
}

.availableLeave {
    display: grid;
    grid-gap: 1em;
    margin-top: 1rem;
}

.availableLeave > div > span {
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
}

.availableLeave .availableLeaveOff {
    color: #E0B457;
    border-bottom: 1px solid #F2F2F5;
    padding-bottom: 20px;
}

.availableLeave .availableLeaveOff > span:first-child > p, .availableLeave .availableLeaveSick > span:first-child > p {
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
}

.availableLeave .availableLeaveOff > span:first-child > p b, .availableLeave .availableLeaveSick > span:first-child > p b {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.availableLeave .availableLeaveOff > span:first-child > p:last-child, .availableLeave .availableLeaveSick > span:first-child > p:last-child {
    color: #28293D;
}

.availableLeave .availableLeaveSick {
    color: #D52F6D;
}

.availableLeave .availableLeaveSick > span:nth-child(2), .availableLeave .availableLeaveOff > span:nth-child(2) {
    margin-top: 8px;
    color: #718096;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

.availableLeave .availableLeaveSick > .availableLeaveBar, .availableLeave .availableLeaveOff .availableLeaveBar {
    width: 100%;
    margin-top: 10px;

}

.availableLeaveBarSickLeave {
    background: #FAE9EF;
    height: 12px;
    border-radius: 22px;
}

.cardRoot .cardTableLeave .card .cardTableInnerRequests {
    display: flex !important;
    justify-content: space-between;
}

.cardTable .card .cardTableInner, .cardTable .card .cardTableHolidayInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    border-bottom: 1px solid #F2F2F5;
    padding: 8px 0;
    color: var(--color-black700);
    font-size: 14px;
    line-height: 24px;
}
.cardTableInner svg{
    fill: none;
}

.cardRoot .cardTable .card .cardTableInner > span:first-child {
    cursor: pointer;
}

.cardTableBtn {
    margin-top: 1rem;
}

.cardRoot .cardTableLeave .card .cardTableInner {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 1fr 0fr;
    padding: 8px 0;
    cursor: pointer;
    border-bottom: 1px solid #F2F2F5;
}

.cardTableInner .cardTableInnerNameLast {
    color: var(--color-black500);
}

.cardRoot .cardTableLeave .card .cardTableInner > span {
    display: grid;
    color: var(--color-black700);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

.cardRoot .cardTableLeave .card .cardTableInner > span > span:last-child {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: var(--color-black500)
}

.cardTable .card .cardTableHolidayInner > span:last-child {
    color: #018782;
    font-weight: 500;
}

.cardRoot .cardTableLeave .card .cardTableInner img, .cardRoot .cardTableLeave .card .cardTableInner > svg {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    object-fit: cover;
}

.cardTable .card .cardTableInner > span:last-child {
    background: #F2F2F5;
    border-radius: 8px;
    padding: 4px 8px;
}

.cardTableInnerRequests .counts {
    display: flex;
    justify-content: center;
    width: 30px;
    padding: 4px 8px;
    border-radius: 8px;

}

.cardTableInnerRequests .off {
    background: #FBF5E9;
    color: #E0B457 !important;
}

.cardTableInnerRequests .leave {
    background: #FAE9EF;
    color: #D52F6D !important;
}

.cardTableInnerRequests .oneOne {
    background: #F1F8FF;
    color: #0373FE !important;
}
