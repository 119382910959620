.wrapper {
  flex: 1;
  border: 1px solid #f2f2f5;
  border-radius: 16px;
  padding: 16px;
}

.iconWrapper {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.daysLeft {
  margin-left: 2px;
  font-size: 14px;
  color: var(--color-black500);
  margin-top: 24px;
}

.buttonsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.addNewText {
  color: #28293d;
  background-color: #f2f2f5;
}
.addNewText:hover {
  color: #28293d;
  background-color: transparent;
  border: 1px solid #28293d;
}

.mainInfo {
  display: flex;
  align-items: flex-end;
  margin-bottom: 25px;
  margin-top: 15px;
}

.mainText {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  height: 45px;
}
