html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}


:root {
  /* Blue Colours */
  --color-blue-100: #F1F8FF;
  --color-blue-200: #DBEDFF;
  --color-blue-300: #80B8FE;
  --color-blue-400: #4D9CFE;
  --color-blue-500: #0373FE;
  --color-blue-600: #015BCB;
  --color-blue-700: #014498;

  --color-green600: #418667;
  --color-green100: #E5F7F1;

  --color-pink100: #FAE9EF;
  --color-pink500: #D52F6D;

  --color-yellow100: #F8EED9;
  --color-warm-yellow100: #FBF5E9;
  --color-yellow400: #E0B457;
  --color-yellow500: #D8A12C;

  --color-black700: #28293D;
  --color-black600: #394253;
  --color-black500: #718096;
  --color-black400: #A0AEC0;
  --color-black300: #CBD5E0;
  --color-black200: #EDF2F7;
  --color-black100: #F7FAFC;

  --color-text-light500: #F7FAFC;
  --color-text-light400: #EBEBF0;
  --color-text-light300: #F2F2F5;
  --color-text-light200: #FAFAFC;
  --color-text-light100: #FFFFFF;

  --color-gray-50: #EBEDF0;
  --color-gray-100: #E1E3E6;
  --color-gray-200: #F9F9F9;
  --color-gray-500: #76787A;
  --color-gray-550: #5D5F61;
  --color-gray-600: #2C2D2E;

  --color-base: #000000;
  --color-primary: #0373FE;
  --color-turquoise-100: #E6F7F6;
  --color-turquoise-300: #4EC3BF;
  --color-turquoise-500: #018782;
  --color-french-wine-100: #FAE9EF;
  --color-french-wine-300: #E76294;
  --color-french-wine-400: #D52F6D;

  --color-error: #D52F6D;
  --color-error-light: rgba(230, 70, 70, 0.1);
  --color-error-light: #FAEBEB;
  --color-border: var(--color-black300);
  --bg-main: #FFFFFF;
  --bg-light: #FAFAFC;
  --bg-table-header: #FCFCFD;
  --font-family-base: 'Inter', sans-serif;

  --border-radius: 4px;
  --button-border-radius: 12px;

  --z-index-header: 1010;
  --z-index-aside: 1020;
  --z-index-backdrop: 1030;
  --z-index-modal: 1040;
  --z-index-menu: 1050;

  /*Tooltip colors*/
  --tooltip-text-color: #FFFFFF;
  --tooltip-background-color: #000000;
  --tooltip-margin: 10px;
  --tooltip-arrow-size: 6px;

  --aside-width: 223px;
  --aside-width-small: 72px;
  --main-padding-x: 40px;
  --main-padding-y: 24px;
}

html, body, #root {
  height: 100%;
}

body {
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-base);
  font-family: var(--font-family-base);
  background-color: var(--bg-main);
}

.modal-open {
  overflow: hidden;
}


::-webkit-scrollbar{
  width: 0;

}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

@media (min-width: 992px) {
  body {
    font-size: 16px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  color: var(--color-primary);
  text-decoration: none;
}

svg {
  fill: currentColor;
}

.cursorPointer {
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
}

.spaceBetween {
  justify-content: space-between;
}

.textBlack600 {
  color: var(--color-black600) !important;
}

.mLeft2 {
  margin-left: 2px;
}

.mTop2 {
  margin-top: 2px;
}

.mRight2 {
  margin-right: 2px;
}

.mBottom2 {
  margin-bottom: 2px;
}

.mLeft4 {
  margin-left: 4px;
}

.mTop4 {
  margin-top: 4px;
}

.mRight4 {
  margin-right: 4px;
}

.mBottom4 {
  margin-bottom: 4px;
}

.mLeft8 {
  margin-left: 8px;
}

.mTop8 {
  margin-top: 8px;
}

.mRight8 {
  margin-right: 8px;
}

.mBottom8 {
  margin-bottom: 8px;
}

.mLeft16 {
  margin-left: 16px;
}

.mTop16 {
  margin-top: 16px;
}

.mRight16 {
  margin-right: 16px;
}

.mBottom16 {
  margin-bottom: 16px;
}

.mLeft24 {
  margin-left: 24px;
}

.mTop24 {
  margin-top: 24px;
}

.mRight24 {
  margin-right: 24px;
}

.mBottom24 {
  margin-bottom: 24px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexDirectionColumn {
  flex-direction: column;
}

.padding16 {
  padding: 16px;
}

.padding24 {
  padding: 24px;
}

.paddingHorizontal24 {
  padding-left: 24px;
  padding-right: 24px;
}

.deletePadding {
  padding: 0!important;
}

.alignItemsCenter {
  align-items: center;
}
.pointer{
  cursor: pointer;
}

.fontSize12 {
  font-size: 12px;
}

.bold {
  font-weight: bold;
}
:root .MuiPopover-paper{
  box-shadow: none;
  transition: 0s!important;
}
