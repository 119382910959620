.root {
    padding: 2rem 3rem;
}
.topContentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profileWrapperRight {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.buttonDefault, .button{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    display: flex;
    gap: .5rem;
    align-items: center;
    color: #28293D;
    height: 40px;
}
.buttonDefault:hover > svg > path{
    stroke: #fff;
}
.buttonError {
    font-family: 'Inter', sans-serif;
    background: #FAE9EF;
    color: #D52F6D
}
.buttonError:hover{
    background: transparent;
    border: 1px solid #D52F6D;
    color: #D52F6D;
    opacity: .9;
}
.benchHistory {
    margin: 2rem 0;
}
.benchHistory .emptyTable{
    margin: 4rem 0;
    display: grid;
    justify-items: center;
    gap: 5px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    color: #28293D;
}
.benchHistory .emptyTable > span:last-child{
    font-size: 14px;
    line-height: 24px;
    color: #394253;
    font-weight: 400;
}
.benchStatus .header {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-top: 2rem;
}
.benchStatus .header .title, .benchHistory .title{
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
}
.benchHistory .title{
    margin-bottom: 1rem;
}
.benchStatus .header > span{
    font-size: 12px;
}
.notBench {
    background: #FAFAFC;
    border-radius: 8px;
    color: #718096;
    padding: 4px 8px;
}

.benchHalf {
    background: #F1F8FF;
    color: #0373FE;
    border-radius: 8px;
    padding: 4px 8px;
}

.bench {
    background: #FAE9EF;
    color: #D52F6D;
    border-radius: 8px;
    padding: 4px 8px;
}

.removed {
    background: #E6F7F6;
    color: #018782;
    border-radius: 8px;
    padding: 4px 8px;
}
