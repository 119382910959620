.root {
  display: flex;
  flex-direction: column;
  padding: var(--main-padding-y) var(--main-padding-x);
}

.title {
  display: flex;
  margin-bottom: 28px;
  justify-content: space-between;
}

.containerFilter {
  display: flex;
  gap: 28px;
}

.placeHolder {
  font-size: 14px !important;
  text-wrap: nowrap !important;
}

.customSelect {
  max-width: 220px;
  width: 100%;
}

.containerItems {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 28px;
}

.rootResetBtn:hover {
  background-color: transparent;
}
.rootResetBtn {
  margin-top: 7px;
  padding: 2px 8px;
  border-radius: 8px;
  background-color: #fae9ef;
  color: #d52f6d !important;
  height: 28px;
}

.deleteSubTitle {
  color: #394253;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
}

.deleteSubTitleBold {
  font-weight: 700;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
