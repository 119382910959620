.form {
  display: grid;
  grid-gap: 20px;
}

.description {
  color: var(--color-black400);
  margin-top: 4px;
}

.imageWrap {
  text-align: center;
  padding-top: 30px;
}

.divider {
  margin: 1.25rem 0;
}