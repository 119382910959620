.rootCell {
  gap: 0px !important;
  padding: 0px !important;
  /* align-items: ; */
  align-self: self-start;
}
.rootTableRowTD:hover {
  background-color: #f2f2f5;
  transition: 0.2s;
}

.rootTableRowTD {
  vertical-align: top;
  transition: all 0.2s;
  border: 1px solid #f2f2f5 !important;
}

.rootTableRowTD td {
  transition: all 0.2s;
  padding: 8px 16px !important;
  border: 0px !important;
}

.tableRowCompact {
  transition-delay: 0.2s;
  transition: all 0.2s;
}
.tableRowCompact td {
  padding: 8px 0px 4.5px 0px !important;
  transition: all 0.2s;
}

.rootTableRow th {
  padding: 12px 16px !important;
  font-size: 14px !important;
  border: 0px !important;
}

.row {
  display: flex;
  align-items: center;
}

.mL {
  margin-left: 32px;
}

.table {
  border: none;
  overflow: visible !important;
  user-select: none;
}

.cardAvatar {
  display: flex;
  align-items: center;
  color: #28293d;
  font-size: 14px;
}

.cardAvatar img {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 6px;
  object-fit: cover;
}

.cardAvatar p {
  color: #28293d;
}

.subTitle {
  color: #718096;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
