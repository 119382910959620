.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  min-width: 52px;
  padding: 9px 16px;
  background-color: #E1E3E6;
  border-radius: var(--button-border-radius);
  border: 1px solid transparent;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.fullWidth {
  width: 100%;
}

.small {
  font-size: 14px;
  font-weight: 500;
}

.wide {
  min-width: 10rem;
}

.iconWrap {
  display: inline-flex;
  width: 20px;
  align-items: center;
  height: 20px;
}

.iconEnd {
  margin-left: 16px;
}

.iconStart {
  margin-right: 6px;
}

.small .iconStart {
  margin-right: 8px;
}

.text {
  color: inherit;
  padding: 4px 0 4px 0;
  background-color: transparent;
}

.text.primary {
  color: var(--color-primary);
}

.text.error {
  color: var(--color-error);
}

.text.muted {
  color: var(--color-gray-550);
}

.contained {
  background-color: var(--color-black100);
  color: var(--color-blue-500);
}

.contained:hover {
  background-color: var(--color-blue-500);
  color: var(--bg-main);
}

.contained.primary {
  color: #ffffff;
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}

.blackBorder {
  color: var(--color-base);
  border-color: var(--color-base);
  background-color: var(--bg-main);
}

.contained.error {
  color: var(--color-french-wine-400);
  border-color: var(--color-french-wine-100);
  background-color: var(--color-french-wine-100);
}

.contained.black {
  color: #ffffff;
  background-color: var(--color-black700);
}

.contained.black:hover {
  background-color: var(--color-black600);
}

.contained.black.disabled {
  color: var(--color-black400);
  background-color: var(--color-black300);
}

.outlined {
  color: var(--color-gray-500);
  border-color: var(--color-gray-500);
  background-color: transparent;
}

.outlined.primary {
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.primary:hover {
  background-color: transparent;
  color: var(--color-blue-500);
}

.blackBorder:hover {
  color: var(--bg-main);
  background-color: var(--color-black700);
}

.outlined.error {
  color: var(--color-error);
  border-color: var(--color-error);
}

.outlined.light {
  border-color: var(--color-gray-50);
}

.disabled {
  cursor: not-allowed;
}
