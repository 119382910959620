.textAreaWrapper {
    position: relative;
    background: var(--color-text-light200);
    border-radius: 4px;
    font-size: 15px;
    padding: 8px;
    border: none;
    width: 100%;
    height: 92px;
    resize: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-family: var(--font-family-base);
    overflow-x: hidden;
}

.textAreaWrapper::placeholder {
    font-weight: 400;
    font-size: 15px;
    color: var(--color-black400);
    font-family: 'Inter', sans-serif;
}

.errorBorder {
    border: 1px var(--color-error) solid;
}

.optional{
    color: #A0AEC0;
    font-size: 14px;
}
.required {
    color: var(--color-error);
}

.subTitle {
    color: var(--color-black500);
}
