.root {
  display: inline-flex;
  margin-bottom: 0.5rem;
  color: var(--color-black600);
}

.muted {
  color: #A0AEC0;
}

.noMargin {
  margin: 0;
}

.marginSm {
  margin-bottom: 0.25rem;
}
