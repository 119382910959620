.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  padding: var(--main-padding-y) var(--main-padding-x);
}

.title {
  display: flex;
  margin-bottom: 44px;
  justify-content: space-between;
}

.containerPlug {
  height: calc(100vh - 56px);
}
