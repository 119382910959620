.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}

.icon img {
  height: 45px;
  height: 45px;
}

.title {
  text-align: center;
  color: #a0aec0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 8px 0;
}

.containerPalette {
  display: flex;
}

.paletteItem {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  margin-right: 8px;
}

.paletteItem {
  cursor: pointer;
}

.marginNone {
  margin-right: 0px;
}

.relative {
  position: relative;
}
.cardInfoEmoji {
  width: fit-content;
  border: none;
  top: 106px;
  transform: translateX(calc(-50% + 48px));
  /* width: 200px; */
}
