.profileModal {
  padding: 0;
  padding-top: 32px;
  border: 1px solid #ebebf0;
  border-radius: 8px;
  overflow: visible;
  user-select: none;
}

.btn {
  height: 32px;
  width: 32px;
  border-radius: 8px;

  background-color: #fafafc;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn:hover {
  transition: 0.2s;
  background-color: transparent;
}

.iconStyle {
  height: 16px;
  width: 16px;
}
