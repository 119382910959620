.bottomText {
  text-align: center;
  margin-top: 1rem;
}

.imageWrap {
  text-align: center;
  padding: 3rem 0 1.25rem;
}

.description {
  color: var(--color-black400);
  margin-top: 0.25rem;
}

.form {
  display: grid;
  grid-gap: 1.25rem;
}

.divider {
  margin: 1.25rem 0;
}