.container {
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 8px;
  display: grid;
  gap: 4px;
  transition: 0.2s;
  flex-wrap: wrap;
}

.container:hover {
  transition: 0.2s;
  background-color: #fafafc;
}

.text {
  color: #394253;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.search {
  color: #28293d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  appearance: none;
  caret-color: #0373fe;
  background-color: #fafafc;
  width: 100%;
  padding: 8px;
}
.search:focus {
  outline: none;
}

.search::placeholder {
  color: #a0aec0;
}

.main {
  overflow-y: scroll;
  border-radius: 8px;
  max-height: calc(40.5px * 5);
  transition: 0.2s;
}

.main :last-child {
  border-bottom: 0px;
}

.card {
  padding: 8px 16px;
  border-bottom: 1px solid #f2f2f5;
  display: flex;
  justify-content: space-between;
  transition: 0.2s;
}

.card:hover {
  transition: 0.2s;
  background-color: #fafafc;
}

.cardOn {
  background-color: #fafafc;
}

.containerCardIcon {
  height: 24px;
  width: 0px;
  margin-right: 0px;
  overflow: hidden;
  pointer-events: all;
  transition: 0.2s;
}

.containerCardIconOn {
  min-width: 24px;
  margin-right: 16px;
  transition: 0.2s;
  cursor: not-allowed;
}

.cardInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
  flex: 1;
}

.containerChevron {
  height: 24px;
  min-width: 24px;
  pointer-events: none;
}

.containerChevron path {
  fill: #718096;
}

.cardText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
  color: #28293d;
  font-size: 14px;
  font-weight: 500;
}

.containerSelectLevel {
  display: none;
  position: absolute;
  right: 0px;
  transform: translateX(100%);
  bottom: 40px;
}

.showSelect {
  display: block;
  border-radius: 8px;
  border: 1px solid #fafafc;
  background: #fff;
  width: 288px;
  box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12),
    0px 8px 18px -6px rgba(24, 39, 75, 0.12);
}

.levelLanguageText {
  color: #a0aec0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 8px;
  padding-bottom: 0px;
}

.containerList {
  display: flex;
  flex-direction: column;
  max-height: calc(40.5px * 5);
  overflow-y: scroll;
}

.containerList :last-child {
  border-bottom: 0px;
}

.mainInfo {
  display: flex;
  align-items: center;
  max-width: calc(300px - 126px);
}

.cardSubText {
  font-size: 12px;
  text-align: center;
  color: #a0aec0;
  margin-left: 4px;
}

.plug {
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-size: 14px;
  color: #a0aec0;
}
