.root {
  transition: 0.2s;
  vertical-align: top;
}
.root:hover {
  transition: 0.2s;
  background-color: #f2f2f5;
}

.root td {
  transition: all 0.2s;
  padding: 16px !important;
  min-height: 32px;
  border-top: 1px solid #f2f2f5 !important;
  /* border-bottom: 0px !important; */
  /* border-bottom: 0.5px solid red !important; */
}

.clearLP {
  padding-left: 0 !important;
}

/*.addedLP {*/
/*  padding-left: 16px;*/
/*}*/

.optionsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
  position: relative;
}
.boldText {
  font-weight: 700;
}
.tableCellOptions {
  height: 32px;
  width: 32px;
  transition: 0.2s;
  opacity: 0;
}

.tableCellActive {
  transition: 0.2s;
  opacity: 1;
}

.optionsContainerStyle {
  /*width: max-content;*/
  position: absolute;
  border: none;
  top: 0;
  right: 2.1rem;
  width: 230px !important;
}

.departmentStatus {
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  display: flex;
  align-items: center;
}

.containerStatuses {
  display: grid;
  gap: 8px;
  margin-top: 3px;
}

.emoji {
  margin-right: 8px;
  line-height: 16px;
}
.statusName {
}

.wrapperCell {
  height: 32px;
  display: flex;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}
.wrapperCellActive {
  cursor: pointer;
}
