/*.flexRow {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    gap: 16px;*/
/*}*/

.dateWrapper {
    padding: 0 8px;
    border-radius: 4px;
    align-items: center;
    width: 215px;
    background: var(--color-text-light200);
    margin-bottom: 20px;
}

.dateWrapper span {
    color: var(--color-black500);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

