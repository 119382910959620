.wrap {
  position: relative;
  cursor: pointer;
}

.margin {
  margin-bottom: 16px;
}

.inputImage {
  display: flex;
  align-items: center;
  background-color: var(--color-text-light200);
  padding-left: 10px;
}

.fullWidth {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.root {
  display: block;
  -webkit-tap-highlight-color: transparent;
  font: inherit;
  letter-spacing: inherit;
  min-width: 0;
  width: 100%;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  background-color: var(--color-text-light200);
  border: 1px solid var(--color-text-light200);
  border-radius: 4px;
}

.withAdornments {
  padding-right: 45px;
}

.startIconContainer {
  padding-left: 45px;
}

/*.root:focus {*/
/*  border-color: var(--color-primary);*/
/*}*/

.root::-webkit-input-placeholder,
.root::-moz-placeholder,
.root:-ms-input-placeholder,
.root:-moz-placeholder {
  color: var(--color-black400);
}

.root.error {
  border-color: var(--color-error);
}

.required {
  color: var(--color-error);
}

.root:disabled {
  background-color: var(--color-text-light300);
  border-color: var(--color-black200);
}

.endIcon {
  display: flex;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 24px;
  height: 24px;
  color: #333333;
  transition: .3s;
  transform: translateY(-50%);
}

.endIconActive {
  top: 50%;
  transform: translate(0, -50%) rotate(180deg);
  transition: .3s;
}

.inputImages {
  width: 24px;
  height: 24px;

}

.startIcon {
  display: flex;
  position: absolute;
  top: 50%;
  left: 10px;
  width: 24px;
  height: 24px;
  color: #333333;
  transform: translateY(-50%);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--color-text-light200) inset !important;
}

.subTitle {
  color: var(--color-black500);
}

.bold {
  font-weight: 700;
}
