.tBodyRow {
  border-bottom: 1px solid #f2f2f5;
  user-select: none;
}

.tBodyRow:hover {
  background-color: transparent;
}

.tBodyRow td {
  padding: 16px !important;
  border-bottom: 0 !important;
}

.tBodyRow td:first-child {
  width: 24px;
  padding-right: 4px !important;
}

.btnShow {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.btnShow svg {
  rotate: 0deg;
  transition: 0.2s;
}

.btnShowOn svg {
  rotate: -90deg;
  transition: 0.2s;
}

.mainInfo {
  display: flex;
  align-items: center;
}

.mainInfo img {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  margin-right: 4px;
  object-fit: cover;
}

.mainInfo span {
  color: #28293d;
  width: 320px;
  display: flex;
  align-items: center;
}

.additionalContainer {
}

.tBodyRowAdditional {
  transition: 0.2s;
  border-bottom: 0 solid #f2f2f5;
  user-select: none;
  background-color: #fafafc;
}

.tBodyRowAdditional td:first-child {
  width: 44px;
}

.tBodyRowAdditional td:last-child p {
  transition: 0.2s;
  height: 0;
  overflow: hidden;
}

.tBodyRowAdditional td {
  transition: 0.2s;
  padding: 0 16px !important;
  border-bottom: 0 !important;
}

.tBodyRowAdditional td:first-child {
  padding: 0 4px 0 16px !important;
}

.tBodyRowAdditionalOn {
  transition: 0.2s;
  border-bottom: 1px solid #f2f2f5;
}
.tBodyRowAdditionalOn td {
  transition: 0.2s;
  padding: 8px 16px !important;
}
.tBodyRowAdditionalOn td:first-child {
  padding: 8px 4px 8px 16px !important;
}

.additionalTable {
  width: 100%;
}
.wrapperTable {
  padding: 0 !important;
  border-bottom: 0 !important;
}

.mainInfo {
  max-width: 500px !important;
  box-sizing: border-box;
  width: 100%;
  display: flex;
}

.mainInfoAdditional {
  height: 0;
  overflow: hidden;
  transition: 0.2s;
  max-width: 500px !important;
  box-sizing: border-box;
  width: 100%;
}

.mainInfoAdditional span {
  width: 280px;
}

.mainInfoAdditional img {
  margin-left: 40px;
}
.mainInfoAdditionalDisable img {
  margin: 0;
}
.mainInfoAdditional {
  padding-right: -40px;
}

.mainInfoAdditionalDisable {
  padding-left: 40px;
}

.tBodyRowAdditionalOn .mainInfoAdditional {
  transition: 0.2s;
  height: 24px !important;
}

.tBodyRowAdditionalOn td:last-child p {
  transition: 0.2s;
  height: 24px !important;
}

.conas {
  width: calc(100% - 40px) !important;
}

.containerTr {
  height: 0;
  overflow: hidden;
  /* visibility: hidden; */
  table-layout: fixed;
}

.emojiWrapper {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emojiWrapper img {
  margin-right: 0;
}

.plug div {
  width: 24px;
}
.plug {
  padding-right: 4px !important;
}

.containerTime p {
  width: 100px;
}

.dot {
  width: 5px;
  height: 4px;
  background-color: #a0aec0;
  border-radius: 4px;
  margin: 0 4px;
}
