.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  margin-bottom: 17px;
  border-bottom: 1px solid #edf2f7;
}

.tabItem {
  flex-shrink: 0;

  padding: 0.375rem 0;
  width: 8rem;
  min-width: 120px;
  border-radius: 0px;
  border-bottom: 3px solid transparent;
  color: #28293d;
  font-weight: 300;

  white-space: nowrap;
}

.container {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.leftSide {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  margin-right: 16px;
}

.rightSide {
}

.customInput {
  width: 200px;
}

.customInputShowBy {
  width: 200px;
}

.customInputShowBy:nth-child(1) div {
  color: #718096;
  font-size: 14px;
}

.font500 {
  font-weight: 500;
}

.tabItemActive {
  border-bottom-color: var(--color-black700);
}

.containerBtns {
  min-height: 36px;
}

.chip {
  padding: 4px 8px !important;
  height: min-content !important;
  min-width: min-content !important;
}

.chip p {
  font-size: 12px !important;
}

.rootResetBtn {
  margin-left: 16px;
  padding: 2px 8px;
  border-radius: 8px;
  background-color: #fae9ef;
  color: #d52f6d !important;
}

.rootResetBtn {
  margin-right: 8px;
}
.rootResetBtn:hover {
  background-color: transparent;
}

.disableCursor {
  cursor: initial !important;
}
