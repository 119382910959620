.stageCard {
  min-width: 88px;
}

.rowWrapper {
  position: relative;
  transition: 0.2s;
}

.rowWrapper:hover {
  transition: 0.2s;
  background-color: #f2f2f5;
}

.containerFile {
  display: flex;
}

.fileText {
  color: #718096;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 4px;
}

.wrapperFiles {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.optionsContainerStyle {
  /*width: fit-content;*/
  border: none;
  top: 40px;
  right: 0;
  width: 200px !important;
}

.tableCellOptions {
  height: 32px;
  width: 32px;
  transition: 0.2s;
  opacity: 0;
  z-index: 100;
}

.tableCellActive {
  transition: 0.2s;
  opacity: 1;
}

.optionsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
  position: relative;
}


