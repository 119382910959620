.card {
  cursor: pointer;
  display: flex;
  min-width: 150px;
}

.cardTitle {
  color: #28293d;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 8px;
}

.icon {
  display: flex;
}
