.wrapper {
  padding: 16px;
}

.container {
  padding: 2px 8px;
  transition: 0.2s;

  color: #394253;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  font-feature-settings: 'ss02' on, 'clig' off, 'liga' off;
}

.container:hover {
  transition: 0.2s;
  background-color: #fafafc;
}
