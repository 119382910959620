.wrapper {
  border: 1px solid #f2f2f5;
  border-radius: 16px;
  padding: 16px;
  user-select: none;
  flex: 4.5;
  height: min-content;
}

.marginVertical {
  margin: 16px 0;
}

.roleText {
  color: #394253;
  font-size: 14px;
  line-height: 24px;
}

.customTitle {
  font-size: 12px !important;
}

.optionsWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* display: grid; */
  gap: 8px;
}

.cardInfoCalendar {
  width: 315px !important;
}

.divide {
  height: 1px;
  background-color: #ebebf0;
  margin: 16px 0;
}
