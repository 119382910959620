.table {
    border: none;
}

.rootTableRow th {
    padding: 32px 16px 16px 16px !important;
    align-self: center !important;
    border-bottom: 1px solid #f2f2f5 !important;
}

.tbody {
    overflow: visible;
}
