.header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 16px;
  user-select: none;
}

.title {
  color: #2a303c;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.departmentsList {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.departmentsList::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.tabItem {
  flex-shrink: 0;

  padding: 0.375rem 0;
  min-width: 120px;
  border-radius: 0;
  border-bottom: 3px solid transparent;

  white-space: nowrap;
}

.tabItemContainer {
  display: flex;
  align-items: center;
  padding: 0 4px;
}

.tabItemBadge {
  margin-left: 0.5rem;
}

.tabItemActive {
  border-bottom-color: var(--color-black700);
}
