/* Modal */

.maxWidthModal {
  max-width: 810px;
  border: 1px solid #ebebf0;
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.closeIcon {
  background-color: #fafafc;
  height: 32px;
  width: 32px;
  border-radius: 8px;
}

.closeIcon svg {
  rotate: 45deg;
  width: 20px;
  height: 20px;
}
.closeIcon path {
  stroke: #718096;
  stroke-width: 2px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.flex {
  flex: 1;
}

.title {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #28293d;
  margin-bottom: 4px;
}

.containerCheckBoxes {
  flex: 1;
  height: 42px;
  display: flex;
  justify-content: space-between;
}
.checkbox path {
  fill: #28293d;
  border-radius: 10px;
}

.checkbox {
  margin-right: 7px;
}

.checkBoxItem {
  display: flex;
  align-items: center;
}

.checkBoxText {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #28293d;
}

.outlinedBtn {
  border: 1px solid #28293d;
  color: #28293d;
  margin-right: 16px;
}

.baseBtn {
  width: 115px;
}

.positionButtons {
  display: flex;
  justify-content: flex-end;
}

.overlay {
  z-index: 1050;
}

.saveModal {
  z-index: 1051;
  max-width: 420px;
}

.saveModalContainerButtons {
  display: flex;
  justify-content: space-between;
}
