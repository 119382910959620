.root {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-size: 11px;
  line-height: 1;
  padding: 5px 4px;
  font-weight: 700;
  min-width: 24px;
  min-height: 24px;
  border-radius: 8px;
}

.rounded {
  border-radius: 50%;
}

.default {
  color: var(--color-black700);
  background-color: var(--color-text-light300);
}

.error {
  color: #ffffff;
  background-color: var(--color-error);
}

.primary {
  color: #ffffff;
  background-color: var(--color-primary);
}