.relative {
  position: relative;
}
.containerOptions {
  position: absolute;
  width: 100%;
  display: none;
  z-index: 1;
  border: 1px solid #fafafc;
  background-color: white;

  margin-top: 4px;
  border-radius: 8px;
  box-shadow: 0 12px 42px -4px rgba(24, 39, 75, 0.12),
    0 8px 18px -6px rgba(24, 39, 75, 0.12);
  overflow-y: auto;
  opacity: 1;
}

.menuAbove {
  transform: translateY(-100%);
  top: 24px;
  margin-top: 0;
}

.showOptions {
  max-height: 400px;
  display: block;
}

/*.showOptionsAnimation {*/
/*  animation: animationBackground 0.2s forwards;*/
/*}*/
@keyframes animationBackground {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.root::placeholder {
  font-size: 16px;
}
.option {
  height: 40px;
  display: flex;
  gap: 5px;
  align-items: center;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.limitedViewOption {
  max-height: calc(40px * 5);
  overflow: auto;
}
.optionImages > div {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.avatar {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.option:hover {
  background-color: #fafafc;
  cursor: pointer;
}

.borderTop {
  border-top: 1px solid #f2f2f5;
}

.smallPadding {
  padding-left: 8px;
  padding-right: 0;
}

.multiSelectItems {
  /* margin-left: 26px; */
}

.errorLabel {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #d52f6d;
  line-height: 18px;
  min-height: 18px;
}

.additionalBlockWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  gap: 4px;
}
.noResults{
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: var(--color-black300);
  margin: 2rem auto;
}

.additionalBlockContainer {
  margin-top: 4px;
  border-radius: 8px;
  background-color: #fafafc;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 4px;
}
.additionalBlockContainer p {
  color: #28293d;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  margin-right: 8px;
}

.additionalBlockContainer svg {
  fill: #a0aec0;
  height: 16px;
  width: 16px;
  rotate: 45deg;
}

.multiSearch {
  padding: 8px;
  line-height: 24px;
  outline: none;
  border: none;
  background-color: #fafafc;
  width: 100%;
  color: #28293d;
}

.multiSearch::placeholder {
  color: #718096;
  font-size: 16px;
  line-height: 24px;
}

.plug {
  line-height: 24px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #28293d;
  font-size: 14px;
}
