.container {
  display: flex;
  align-items: center;
  padding: 2px 6px;
  width: max-content;
}

.container:hover {
  cursor: pointer;
}

.title {
  color: #cbd5e0;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.2px;
  margin-left: 6px;
}

.icon {
  width: 18px;
  height: 20px;
}

.isCalendar svg {
  height: 16px;
  width: 16px;
}

.isCalendar path {
  stroke: #cbd5e0;
}
