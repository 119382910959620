.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding-top: 40px;
}

.img {
  height: 222px;
  width: 222px;
}

.text {
  color: #28293d;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}
