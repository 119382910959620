.root {
  font-size: 12px;
  line-height: 18px;
  padding: 4px 8px;
  border-radius: 8px;
  white-space: nowrap;
  display: flex;
  min-width: 90px;
  align-items: center;
  justify-content: center;
}

.Accepted {
  color: #018782;
  background-color: #e6f7f6;
}

.Viewed {
  color: #d8a12c;
  background-color: #fbf5e9;
}

.Pending {
  color: #1958e3;
  background-color: #f1f8ff;
}
