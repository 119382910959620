.wrapper {
  padding: 16px 17px 16px 17px;
}

.container {
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 8px;
  width: 242px;
  transition: 0.2s;
}

.container:hover {
  transition: 0.2s;
  background-color: #fafafc;
}

.title {
  color: #28293d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 4px;
}

.comment {
  color: #718096;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.textArea {
  font-family: 'Inter', sans-serif;
  margin-top: 1px;
  border: none;
  border-top: 1px solid #ebebf0;
  font-family: inter;

  background-color: #fafafc;
  height: 72px;
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
  resize: none;
  caret-color: #0373fe;
}

.textArea:focus {
  outline: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  cursor: pointer;
  right: 0;
  transform: translateY(calc(-100% - 5px));
  background-color: #fafafc;
  width: 0px;
  height: 72px;
  overflow: hidden;
  transition: 0.2s;
}
.visible {
  transition: 0.2s;
  width: 279px;
}

.absoluteContainer {
  height: 72px;
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.plugTitle {
  color: #a0aec0;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 8px;
  white-space: nowrap;
}
