.container {
  flex: 1;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid#F2F2F5;
  height: min-content;
}
.grid {
  margin-top: 1rem;
  display: grid;
  gap: 16px;
  user-select: none;
  padding-right: 35px;
}
.container .grid :last-child{
  grid-template-columns: 1fr;
  grid-gap: 10px;
}
.divide {
  height: 1px;
  background-color: #ebebf0;
}

.cardContainer {
  display: flex;
  align-items: center;
}

.cardTitle {
  color: #718096;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  width: 35%;
}

.testLink {
  width: 30px;
  height: 30px;
}

.cardInfoStatus {
  width: 105px !important;
  margin-top: 7px;
  padding: 2px 0;
}

.cardInfoManagers {
  width: 370px !important;
  top: -5px;
  left: -1px;
}
.cardInfoDescription {
  top: 35px;
  border: none !important;
}

.option {
  display: block;
  padding: 6px 8px;
  cursor: pointer;
}

.option:hover {
  background-color: var(--color-gray-200);
}

.cardInfoCalendar {
  width: 315px !important;
}
