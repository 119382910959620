.root {
  padding: var(--main-padding-y) var(--main-padding-x);
  height: 100vh;
  overflow: auto;
}

.tabs {
  margin-top: 2.5rem;
  border-bottom: 1px solid var(--color-black300);
}

.tabItem {
  flex-shrink: 0;
  grid-gap: 0.5rem;

  padding: 0.375rem 0;
  width: 8rem;
  min-width: 120px;
  border-radius: 0;
  border-bottom: 3px solid transparent;
  color: #28293d;
  font-weight: 300;

  white-space: nowrap;
}

.tabItemBadge {
  margin-left: 0.5rem;
}

.tabItemActive {
  font-weight: 500;
  border-bottom-color: var(--color-black700);
}
