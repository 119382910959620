.root {
  display: flex;
  align-items: center;
  white-space: nowrap;
  grid-gap: 10px;
}

.icon {
  width: 1rem;
  height: 1rem;
  display: flex;
}

.sortable {
  cursor: pointer;
}

.center {
  justify-content: center;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.error {
  color: var(--color-error);
}

.muted {
  color: #152935;
}

.primary {
  color: var(--color-primary);
}

.iconSorted {
  transform: rotate(180deg);
}