.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.padding0 {
  padding: 0;
}

.paddingTop {
  padding-top: 24px;
}

.header {
  padding: 24px 24px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  display: flex;
  flex: 1;
  overflow-y: scroll;
}

.title {
  color: #28293d;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.footer {
  display: flex;
  padding: 24px 24px;
  justify-content: space-between;
}

.plugSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.plugTitle {
  color: var(--black-black-700, #28293d);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.editBtn {
  border: 1px solid #28293d !important;
  color: #28293d;
  transition: 0.2s;
}

.editBtn path {
  fill: #28293d;
}
.trashBtn span,
.editBtn span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.trashBtn {
  background-color: #fae9ef !important;
  color: #d52f6d !important;
}
.trashBtn:hover {
  transition: 0.2s;
  opacity: 0.5;
}

.editBtn:hover {
  transition: 0.2s;
  opacity: 0.5;
}

.btnIcon {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: #fafafc;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}
.btnIcon:hover {
  cursor: pointer;
  background-color: #fae9ef;
  transition: 0.2s;
}
.btnIcon:hover svg {
  fill: #d52f6d;
  transition: 0.2s;
}

.btnIcon svg {
  rotate: 45deg;
  fill: #718096;
  height: 20px;
  width: 20px;
}
