.title {
  color: #28293d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
}

.containerRadio {
  display: flex;
  justify-content: space-between;
}

.radioBase {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid #dddde2;
  margin-right: 8px;
  transition: 0.2s;
}

.radioOn {
  transition: 0.2s;
  border: 7px solid #28293d;
}

.row {
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.row:hover {
  cursor: pointer;
}

.radioTitle {
  color: #2a303c;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.select {
  display: flex;
  height: 24px;
}

.select:hover {
  cursor: pointer;
}

.selectTitle {
  color: #a0aec0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 8px;
}
