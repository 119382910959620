.container {
  padding: 16px;
  width: 306px;
}

.title {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
}

.containerDays {
  border-collapse: collapse;
  margin-bottom: 24px;
}

.containerDay {
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  background-color: #fafafc;
  border-collapse: collapse;
  box-sizing: border-box;
  cursor: pointer;
  color: #28293d;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
}
.containerDay:hover {
  transition: 0.1s;
  background-color: #f1f8ff;
  color: #0373fe;
  font-weight: 600;
  border: 1px solid #fafafc;
}

.selectedDay {
  /* border: 1px solid #fafafc; */
  color: white;
  background-color: #0373fe;
  transition: 0.1s;
  font-weight: 700;
}

.selectedDay:hover {
  transition: 0.1s;
  background-color: #0373fe;
  color: white;
  font-weight: 600;
  /* border: 1px solid #fafafc; */
}

.includedBoxLeft {
  border-radius: 0px 8px 8px 0px;
}

.includedBoxRight {
  border-radius: 8px 0px 0px 8px;
}

.round {
  border-radius: 8px;
}

.includedBoxCenter {
  background-color: #f1f8ff;
  font-weight: 500;
  color: #0373fe;
  border: 1px solid #fafafc;
}
.includedBox:hover {
  color: white;
}

.containerButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.outlinedBtn {
  border: 1px solid #28293d;
  color: #28293d;
}

.baseBtn {
  width: 110px;
}

.input {
  width: 125px;
}
.input input::-webkit-inner-spin-button,
.input input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.containerInputs {
  display: flex;
  justify-content: space-between;
}
