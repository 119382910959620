.cardInfoStatus {
  width: 105px !important;
  margin-top: 7px;
}

.cardInfoManagers {
  width: 370px !important;
  top: -5px;
  left: -1px;
}
.cardInfoDescription {
  top: 35px;
  border: none !important;
  width: 153.8%;
}

.option {
  display: block;
  padding: 6px 8px;
  cursor: pointer;
}

.option:hover {
  background-color: var(--color-gray-200);
}

.cardInfoCalendar {
  width: 315px !important;
}
