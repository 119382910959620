.root .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.root .navContainer {
    display: flex;
    justify-content: space-between;
    overflow: auto;
    margin-bottom: 1rem;
    border-bottom: 1px solid #edf2f7;
    margin-top: 2.5rem;
}

.root .filters {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    margin: 1.5rem 0;
}

.root .filters .clickerLeftCheckbox {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 1rem;
    color: #8F9BB3;
    font-size: 14px;
    line-height: 22px;
}

.root .filters input::placeholder {
    font-size: 14px;
    color: #718096;
    line-height: 22px;
}

.memberInfo {
    display: flex;
    align-items: center;
    gap: 1rem
}

.memberInfo img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 4px;
}

.notBench {
    background: #FAFAFC;
    border-radius: 8px;
    color: #718096;
    padding: 4px 8px;
}

.benchHalf {
    background: #F1F8FF;
    color: #0373FE;
    border-radius: 8px;
    padding: 4px 8px;
}

.bench {
    background: #FAE9EF;
    color: #D52F6D;
    border-radius: 8px;
    padding: 4px 8px;
}

.removed {
    background: #E6F7F6;
    color: #018782;
    border-radius: 8px;
    padding: 4px 8px;
}
.benchModalProlong{
    height: 530px;
}

.benchModal .title {
    font-size: 20px;
    color: #28293D;
    font-weight: 700;
}

.benchModal .subtitle {
    color: #718096;
    font-size: 16px;
    padding: 1rem 0;
}
.benchModal {
    padding: 12px 0;
}
.benchModal .subtitle b {
    font-weight: 700;
}
.red{
    color:#D52F6D;
}
.benchModal .comment {
    display: grid;
    width: 100%;
    gap: .5rem;
    margin-top: 1.5rem;
    font-size: 14px;
    color: #28293D;
}

.benchModal .buttons {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rootStatus {
    padding: 18px 26px;
    border: 1px solid #EBEBF0;
    border-radius: 16px;
    margin: 1rem 0;
}
.rootStatus .benchStatus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.rootStatus .benchStatus > svg {
    margin-top: 3rem;
}

.rootStatus .benchStatus > div {
    display: grid;
    justify-items: center;
    gap: 16px;
}

.rootStatus .benchStatus > div > div {
    display: grid;
    gap: 6px;
    justify-items: center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #394253;
}

.rootStatus .benchStatus > div > div > span {
    text-align: center;
}

.rootStatus .benchStatus > div:first-child > div > span {
    text-align: left;
}

.rootStatus .benchStatus > div:last-child > div > span {
    text-align: right;
}

.rootStatus .benchStatus > div > div > span:last-child {
    color: #718096;
}

.rootStatus .benchStatus > div:first-child, .benchStatus > div:first-child > div {
    justify-items: start;
}

.rootStatus .benchStatus > div:last-child, .benchStatus > div:last-child > div {
    justify-items: end;
}

.rootStatus .benchStatus .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #2A303C;
}

.rootStatus .wrapper {
    margin: 1.5rem 0 0;
    display: flex;
    align-items: center;
}

.rootStatus .progressBar {
     width: 100%;
 }

.rootStatus .label {
    opacity: 0;
}
.benchOverdue{
    display: flex;
    align-items: center;
    gap: .5rem;
    justify-content: center;
    width: 100%;
    background: #FBF5E9;
    border: 1px solid #F0DAAD;
    padding: 8px 16px ;
}

 .emptyTable{
    margin: 4rem 0;
    display: grid;
    justify-items: center;
    gap: 5px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    color: #28293D;
}
 .emptyTable > span:last-child{
    font-size: 14px;
    line-height: 24px;
    color: #394253;
    font-weight: 400;
}
.tableRowsLast{
    max-width: 350px; /* Set the maximum width before applying ellipsis (adjust the value as needed) */
    overflow: hidden;
    text-overflow: ellipsis; /* Apply ellipsis if content overflows */
    white-space: nowrap; /* Prevent wrapping */
}
