.root {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 14px;
  white-space: nowrap;
  color: var(--color-black500);
  cursor: pointer;
  border-radius: 12px;
  transition: 0.2s;
}

.root:first-child {
  margin-top: 0;
}
.root:first-child:hover {
  transition: 0.2s;
  color: var(--color-primary);
  background-color: var(--color-blue-100);
}

.root:hover {
  transition: 0.2s;
  background-color: var(--color-blue-100);
}

.root:focus {
  transition: 0.2s;
  color: var(--color-black500);
}

.root:global(.active),
.active,
.active:focus {
  transition: 0.2s;
  color: var(--color-primary);
  font-weight: 700;
  background-color: var(--color-blue-100);
}

.inner {
  display: flex;
  align-items: center;
  flex: 1;
}

.inner > svg {
  height: 25px;
  width: 25px;
}
.icon {
  transition: ease-in-out 0.2s;
  height: 20px !important;
  width: 20px !important;
}
.iconActive {
  transform: rotate(180deg);
  transition: ease-in-out 0.2s;
  height: 20px !important;
  width: 20px !important;
}
.iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 10px;
}

.iconWrap svg {
  width: 24px;
  height: 24px;
}

.containerIcon {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  min-width: 20px !important;
}

.containerIcon svg path {
  fill: #cbd5e0 !;
}
