.root {
  display: flex;
  flex: 1;
}

.main {
  width: max-content;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
