.divider {
  border: 2px solid var(--color-blue-500);
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 80vh;
  height: fit-content;
  padding: 0 25px 32px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  padding: 32px 24px 24px 24px;
}

.title {
  color: #28293d;
  font-size: 24px;
  font-weight: bold;
}

.imageContainer {
  width: 100%;
  justify-content: center;
  margin-bottom: 24px;
}

.inputBorder {
  justify-content: left;
  height: 44px;
  position: relative;
  border-radius: 4px;
  padding: 10px 16px;
  color: #28293d;
  font-weight: 500;
  font-size: 14px;
  background-color: var(--color-text-light200);
}

.menuStyle {
  box-shadow: 0 2px 8px rgba(40, 41, 61, 0.08),
    0 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 4px;
  width: 336px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px 10px;
}

.title {
  color: #28293d;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}
.btnIcon {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: #fafafc;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}
.btnIcon:hover {
  cursor: pointer;
  background-color: #fae9ef;
  transition: 0.2s;
}
.btnIcon:hover svg {
  fill: #d52f6d;
  transition: 0.2s;
}

.btnIcon svg {
  rotate: 45deg;
  fill: #718096;
  height: 20px;
  width: 20px;
}

.btnsContainer {
  padding: 0 25px;
}
