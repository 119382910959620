.root {
  display: flex;
  align-items: center;
  font-size: 12px;

  margin-top: 4px;
}

.error {
  color: var(--color-error);
}