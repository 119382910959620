.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  overflow: auto;
  margin-bottom: 12px;
  border-bottom: 1px solid #edf2f7;

}

.tabItem {
  flex-shrink: 0;

  padding: 0.375rem 0;
  width: 8rem;
  min-width: 120px;
  border-radius: 0;
  border-bottom: 3px solid transparent;
  color: #28293d;
  font-weight: 300;

  white-space: nowrap;
}

.tabItemBadge {
  margin-left: 0.5rem;
}

.font500 {
  font-weight: 500;
}
.tabItemActive {
  border-bottom-color: var(--color-black700);
}

.all {
  background-color: #f1f8ff;
  color: #0373fe;
}
.in_progress {
  background-color: #fbf5e9;
  color: #d8a12c;
}

.finished {
  background-color: #e6f7f6;
  color: #02aaa4;
}
.canceled {
  background-color: #fae9ef;
  color: #d52f6d;
}

.container {
  margin-bottom: 20px;
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rootResetBtn {
  margin-left: 8px;
  padding: 2px 8px;
  border-radius: 8px;
  background-color: #fae9ef;
  color: #d52f6d !important;
}
.rootSelectedBtn {
  padding: 2px 8px;
  display: flex;
  border-radius: 8px;
  align-items: center;
}

.rootViewBtn {
  border-radius: 12px;
}

.rootViewBtn {
  color: #28293d;
}

.rootSelectedBtn:hover {
  background-color: transparent;
}

.rootSelectedBtn p {
  color: #28293d;
  margin-right: 7px;
}

.rootSelectedBtn svg path {
  fill: #a0aec0;
}
.rootSelectedBtn svg {
  rotate: 45deg;
}

.rootResetBtn:hover {
  background-color: transparent;
}

.m {
  margin-left: 16px;
}

.rootFilterBtn {
  border-radius: 4px;
}
.rootFilterBtn svg path {
  fill: #718096;
}

.rootFilterBtn:hover svg path {
  fill: white;
}

.rootFilterBtn:hover p {
  color: white;
}

.rootFilterBtn:hover .dot {
  background-color: white;
}

.rootFilterBtn p {
  color: #718096;
  font-weight: 500;
  margin-left: 9px;
}

.dot {
  background-color: #0373fe;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-left: 2px;
}

.leftSide {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.rootDrawer {
  /* margin-top: 0px !important; */
  /* height: 100% !important;
  margin-top: 0px !important; */
  box-shadow: -21px 0 36px -37px rgba(24, 39, 75, 0.1) !important;
}
