.tBodyRow {
  border-bottom: 1px solid #f2f2f5;
}

.tBodyRow td {
  border-bottom: 0 !important;
  padding: 8px !important;
  cursor: pointer;
  border-bottom: 1px solid #f2f2f5 !important;
}

.tBodyRow td:first-child {
  position: sticky;
  left: 0;
  z-index: 0;
  padding: 8px 16px !important;
  border-right: 1px solid #f2f2f5;

  background-color: white;
}

.tBodyRow td:last-child {
  position: sticky;
  right: 0;
  z-index: 0;
  background-color: #fafafc;
  color: #28293d;
}

.containerMember {
  display: flex;
  align-items: center;
  gap: 4px;
}

.wrapperMember:hover {
  cursor: pointer;
}

.wrapperMember {
}
.containerMember img {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.containerMember span {
  color: #28293d;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.dot {
  width: 5px;
  height: 4px;
  background-color: #a0aec0;
  border-radius: 4px;
  margin: 0 4px;
}

.emojiWrapper {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup{
  background: #2A303C;
  padding: 12px;
  display: grid;
  gap: 5px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
}
.popup > div{
  display: flex;
  align-items: center;
  gap: 2.5rem;
  justify-content: space-between;
}
.popup > div > span:first-child{
  display: flex;
  align-items: center;
  gap: 8px;
}
.popup > div > span:first-child > img {
  width: 24px;
  height: 24px;
}
