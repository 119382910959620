.container {
  display: flex;
  gap: 8px;
  align-items: center;
  user-select: none;
}

.clickable {
  cursor: pointer;
}

.container img {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  object-fit: cover;
}

.container p {
  color: #28293d;
  font-size: 14px;
  line-height: 24px;
}

.dot {
  height: 5px;
  width: 5px;
  border-radius: 5px;
  background-color: #cbd5e0;
}

.wrapper {
  display: flex;
  align-items: center;
}

.containerIcon {
  height: 16px;
  width: 16px;
  margin: 0px 8px;
}

.containerIcon path {
  fill: #cbd5e0;
  stroke: #cbd5e0;
  stroke-width: 0.4px;
}
.containerIcon svg {
  height: 18px;
  width: 18px;
}

.icon {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
