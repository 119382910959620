.root {
  display: flex;
  flex-direction: column;
}

.imageWrapper {
  padding: 1rem;
  text-align: center;
}

.button {
  min-width: 110px;
}