.leavePage {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.leavePage .leavePageHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.leavePage .leavePageHeadRight {
    display: flex;
    grid-gap: 1rem;
}

.leavePage .leavePageHeadRight > button:first-child > svg {
    fill: transparent;

}

.leavePage .leavePageHeadRight > button:first-child:hover > svg {
    fill: transparent;
}

.leavePage .leavePageHeadRight > button:first-child:hover > svg path {
    stroke: #fff;
}

.ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    color: #28293D;
}


.iconStyle {
    width: 20px;
    height: 20px;
}

.iconArchive svg {
    height: 15px;
    width: 15px;
}

.iconArchive path {
    fill: #28293d;
    transition: 0.25s;
}

.btn:hover .iconArchive path {
    transition: 0.25s;
    fill: white;
}

.iconArchive {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.tableLeave {
    width: max-content;
    margin-top: 2rem;
    overflow-x: hidden;
}

.tableTitle {
    color: var(--color-black500);
    display: grid;
    justify-items: start;
    text-transform: uppercase;
    font-family: 'Inter', sans-serif;
    width: 229px !important;
}

.members {
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding: 16px 8px;
}

.members > img {
    border-radius: 8px;
    object-fit: cover;
}

.members > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}


.tableTitle > div:last-child {
    border: 1px solid #F2F2F5;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    font-size: 12px;
    line-height: 20px;
    height: 40px;
    margin-top: auto;
    border-right: none;
}

.navContainer {
    display: flex;
    justify-content: space-between;
    overflow: auto;
    margin-bottom: 12px;
    grid-gap: 3rem;
    margin-top: 2.5rem;
    border-bottom: 1px solid #edf2f7;
    width: 100%;
}

.navContainer .searchWrapper {
    border-left: 1px solid red;
}

.selectPeriod {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selectPeriod .clicker {
    display: flex;
    align-items: center;
    grid-gap: 2rem;
    margin-top: 10px;
}

.tableEmpty {
    display: grid;
    width: 100%;
}

.clickerLeft {
    width: 245px;
    user-select: none;
    display: flex;
    justify-content: space-between;
    grid-gap: 8px;
    align-items: center;
    padding: 8px;
    background-color: #FAFAFC;
    border-right: 4px;
    color: #718096;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

.clickerLeftCheckbox {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    color: #718096;
    font-size: 14px;
    line-height: 22px;
}

.leaveTableModal .clickerLeftCheckbox {
    color: #8F9BB3;
}

.clickerLeft > svg {
    cursor: pointer;
}

.clickerLeftModal {
    width: max-content !important;
}

.selectPeriod .clickerRight {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
}

.selectPeriod .clickerRight > div {
    width: 120px;
    height: 40px;
    cursor: pointer;
}

.selectPeriod .clickerRight > div > div > input {
    cursor: pointer;
}

.leaveModal {
    width: 310px;
}

.leaveTableModal {
    width: 900px;
    padding: 32px;
}

.tableInfo {
    display: flex;
    align-items: center;
    gap: .5rem;
    justify-content: center;
}

.tableInfoFirst {
    justify-content: flex-start;
}

.leaveTableInner {
    overflow-y: scroll;

}

.leaveTableInnerNoData {
    display: grid;
    gap: 1rem;
    align-items: center;
    justify-items: center;
    margin: 10rem 0;
}

.leaveTableInnerNoData span {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.leaveTableModal {
    height: 710px;
}

.leaveTableModalHeadSelect {
    width: 200px;
}

.tableInfo img {
    width: 24px;
    height: 24px;
    border-radius: 4px;
}

.leaveTableModal .leaveTableModalHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leaveTableModal .leaveTableModalHead > span > svg {
    cursor: pointer;
}

.leaveTableModal .leaveTableModalHead > span {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1px;
    font-weight: 700;
    color: #394253;
}

.leaveTableModal .leaveTableModalFilters {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem 0;
}

.leaveModal .leaveModalHead {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    border-bottom: 1px solid #EDF2F7;
    padding-bottom: .5rem;
}

.leaveModal .leaveModalTitle {
    display: flex;
    justify-content: space-between;
    padding: 0 0 1.5rem;
    font-size: 24px;
    color: #28293D;
    font-weight: 700;
}

.leaveModalTitle > span > svg {
    cursor: pointer;
}

.leaveModal .leaveModalHead > img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    object-fit: cover;
}

.leaveModal .leaveModalHead > div {
    display: grid;
    justify-items: start;
}

.leaveModal .leaveModalHead > div > span:last-child {
    color: #718096;
    font-size: 14px;
}

.leaveModal .leaveModalMonth {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EDF2F7;
    padding: .7rem 0;
}

.leaveModal .leaveModalMonth > span:first-child {
    font-weight: 700;
    color: #28293D;
}

.leaveModal .leaveModalMonth > span:last-child, .leaveModal .leaveModalCount > div > div > span:last-child {
    color: #718096;
}

.leaveModal .leaveModalCount > div {
    display: grid;
    grid-template-columns: 0fr 1fr 0fr;
    gap: .5rem;
    padding: .5rem 0;
}

.leaveModal .leaveModalCount > div > div {
    display: grid;
    gap: .5rem;
    font-size: 14px;
    color: #28293D;
}

.leaveModal .leaveModalCount > div > div:last-child {
    color: #D52F6D;
    font-weight: 600;
    justify-items: right;
    font-size: 16px;
}

.leaveModal .leaveModalCount > div:last-child > div:last-child > span:first-child {
    color: #D8A12C;
}

.leaveModal .leaveModalCount > div > div:last-child > span:last-child {
    color: #718096;
}

.leaveModal .leaveModalCount > div:first-child {
    border-bottom: 1px solid #EDF2F7;
}

.leaveModal .leaveModalCount > div:first-child > img {
    background: #FAE9EF;
    border-radius: 6px;
}

.leaveModal .leaveModalCount > div:last-child > img {
    background: #FBF5E9;
    border-radius: 6px;
}

.leaveModal .leaveModalCount div img {
    padding: 5px;
}

.selectPeriod .clickerRight > button {
    color: #28293D;
    background: #F2F2F5;
}

.selectPeriod .clickerRight > button:hover {
    opacity: .8;
}

.medical {
    color: #D52F6D;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
}

.off {
    color: #D8A12C;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
}


.MedicalLeave {
    background-color: #FAE9EF;
    border-radius: 0;
}

.DayOff {
    background-color: #FBF5E9;
    border-radius: 0;
}

.MedicalLeaveHalf {
    background: linear-gradient(to bottom, #fef9fb 50%, #FAE9EF 50%);
}

.DayOffHalf {
    background: linear-gradient(to bottom, #fefdf9 50%, #FBF5E9 50%);
}

.HolidayDay {
    background-color: #E5F7F1;
    border-radius: 0;
    z-index: 100;
}

div:global(.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6) {
    background: #FAFAFC;
    border-left: 1px solid #F2F2F5;
}

div:global(.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0) {
    background: #FAFAFC;
    border-right: 1px solid #F2F2F5;
}

div:global(.react-calendar-timeline .rct-header-root) {
    background: transparent;
    color: var(--color-black500);
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: none;
}

div:global(.react-calendar-timeline .rct-vertical-lines .rct-vl) {
    border: none;
}

div:global(.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd) {
    background-color: transparent;
}

div:global(.react-calendar-timeline .rct-dateHeader-primary) {
    color: var(--color-black500);
    display: flex;
    text-transform: uppercase;
    font-size: 12px;
    border-right: none;
    padding: 0 1rem;
    justify-content: flex-start;
    position: fixed;
    left: 0

}

div:global(.react-calendar-timeline .rct-sidebar) {
    border-right: 1px solid #F2F2F5;
    border-left: 1px solid #F2F2F5;
}

div:global(.rct-calendar-header > div) {
    height: 40px !important;
}

div:global(.rct-calendar-header > div:nth-child(2)) {
    border: none;
}

div:global(.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd) {
    background: transparent;
    border-bottom: 1px solid #F2F2F5;
}

div:global(.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even) {
    border-bottom: 1px solid #F2F2F5;
}

div:global(.rct-scroll > div:first-child > div:first-child) {
    background-color: var(--color-blue-500) !important;
}

div:global(.react-calendar-timeline .rct-scroll) {
    overflow-x: hidden;
    border-right: 1px solid #F2F2F5;
}

div:global(.rct-dateHeader) {
    pointer-events: none;
}

div:global(.react-calendar-timeline .rct-horizontal-lines .rct-hl-even) {
    background: #fff;
    border-bottom: 1px solid #F2F2F5;
}

div:global(.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd) {
    border-bottom: 1px solid #F2F2F5;
}

div:global(.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd) {
    background: #fff;
    border-bottom: 1px solid #F2F2F5;
}

div:global(.react-calendar-timeline .rct-horizontal-lines .rct-vl) {
    background: #fff;
    border: 1px solid #F2F2F5;
}

div:global(.react-calendar-timeline .rct-dateHeader:first-child ) {
    border-bottom: none;
}

div:global(.react-calendar-timeline .rct-dateHeader) {
    background: transparent;
    border-top: 1px solid #F2F2F5;
    border-bottom: 1px solid #F2F2F5;
    border-right: 1px solid #F2F2F5;
    border-left: none
}

div:global(.react-calendar-timeline .rct-calendar-header) {
    border: 1px solid #F2F2F5;
    border-top: none;
    border-bottom: none;
}

.rootTableRowTD {
    vertical-align: top;
}

.leaveTableModal thead th:first-child {
    width: 300px;
}

.leaveTableModal thead th {
    border: 1px solid #F2F2F5 !important;
    padding: 8px !important;
}

.leaveTableModal tbody td {
    border: 1px solid #F2F2F5 !important;
}

.leaveTableModal .rootTableRowTD td {
    padding: 8px 12px !important;
    border: 1px solid #F2F2F5;
}

.leaveTableModal .rootTableRow th {
    padding: 8px 12px !important;
    border: 1px solid #F2F2F5 !important;
}
