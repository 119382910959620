.root {
  position: relative;
}

.margin {
  margin-bottom: 16px;
}

.marginSm {
  margin-bottom: 8px;
}

.fullWidth {
  flex-grow: 1;
}
