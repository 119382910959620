.root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name {
  margin-left: 0.5rem;
}

.image {
  border-radius: 8px;
  object-fit: cover;
}

.small .image {
  width: 24px;
  height: 24px;
}

.medium .image {
  width: 32px;
  height: 32px;
}