.wrap {
  position: relative;
  cursor: pointer;
}

.margin {
  margin-bottom: 16px;
}

.fullWidth {
  width: 100%;
}

.root {
  display: block;
  -webkit-tap-highlight-color: transparent;
  font: inherit;
  letter-spacing: inherit;
  min-width: 0;
  width: 100%;
  outline: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--color-text-light200);
  border: 1px solid var(--color-text-light200);
  border-radius: 4px;
  transition: 0.2s;
  resize: vertical;
  min-height: 90px;
}

.withAdornments {
  padding-right: 48px;
}

.startIconContainer {
  padding-left: 48px;
}

.root:focus {
  border-color: #28293d;
  transition: 0.2s;
}

.root::placeholder {
  color: var(--color-black400);
}

.absolute {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: -1;
  /* height: 42px; */
  transition: 0.2s;
  border-radius: 4px;
}

.absoluteFocused {
  top: -2px;
  left: -2px;
  bottom: -2px;
  /* height: 46px; */
  width: calc(100% + 4px);
  border-radius: 6px;
  z-index: -1;
  background-color: #f2f2f5;
  transition: 0.2s;
}

.absoluteError {
  background-color: #fae8ee;
}

/* .root::-webkit-input-placeholder,
  .root::-moz-placeholder,
  .root:-ms-input-placeholder,
  .root:-moz-placeholder */

.root.error {
  border-color: #d62f6d;
}

.required {
  color: var(--color-error);
  margin-left: 4px;
}

.requiredDisabled {
  color: #a0aec0;
}

.root:disabled {
  background-color: var(--color-text-light300);
  border-color: var(--color-black200);
}

.endIcon {
  display: flex;
  position: absolute;
  top: 50%;
  right: 16px;
  width: 24px;
  height: 24px;
  color: #333333;
  transform: translateY(-50%);
  pointer-events: none;
}

.startIcon {
  display: flex;
  position: absolute;
  top: 50%;
  left: 16px;
  width: 24px;
  height: 24px;
  color: #333333;
  transform: translateY(-50%);
  pointer-events: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--color-text-light200) inset !important;
}

.subTitle {
  color: var(--color-black500);
}

.bold {
  font-weight: 700;
}
