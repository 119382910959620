.overlay {
  z-index: 1050;
}

.modal {
  z-index: 1051;
  max-width: 860px;
  max-height: 80vh;
  height: 100%;
  overflow: initial;
  padding: 0;
  display: flex;
  flex: 1;
  border: 1px solid #f2f2f5;
  user-select: none;
}
