.header {
  display: flex;
  margin-bottom: 10px;
  user-select: text;
  justify-content: space-between;
}
.header .headerLeft {
  display: flex;
  grid-gap: 1rem;
}
.header .headerRight {
  background: #fafafc;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
}
.header > svg {
  width: 16px;
  height: 16px;
}
.icon {
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  user-select: none;
}

.icon img {
  height: 45px;
}

.additionalInfo {
  display: grid;
  grid-gap: 8px;
}

.row {
  display: flex;
  align-items: center;
}

.projectTitle {
  color: #2a303c;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}
.status {
  width: min-content;
}

.cardAvatar {
  display: flex;
  align-items: center;
  color: #28293d;
  font-size: 14px;
  margin-left: 12px;
}

.cardAvatar img {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 6px;
}

.cardAvatar p {
  font-weight: 500;
  color: #28293d;
}

.black500 {
  color: #718096;
  font-weight: 400;
}
