.options {
  background-color: red;
}

.title {
  color: #a0aec0;
  font-size: 12px;
  font-weight: 400;
  padding: 8px 8px 4px 8px;
  line-height: 18px;
}

.selectableCard {
  padding: 8px;
  cursor: pointer;
}

.selectableCard:hover {
  background-color: #fafafc;
}

.bgGray {
  background-color: #fafafc;
  width: fit-content;
}

.avatar {
  display: flex;
  align-items: center;
  color: #28293d;
  font-size: 14px;
  padding-right: 4px;
}

.avatar img {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 4px;
}

.containerOptions {
  top: 78px;
}

.divide {
  height: 1px;
  background-color: #f2f2f5;
  margin: 16px 8px;
}

.containerButton {
  padding: 0 16px 16px 16px;
}

.btn {
  border-color: #28293d !important;
  color: #28293d !important;
}

.btn path {
  stroke: #28293d;
}

.selectionWrapper {
  z-index: 1060;
}
.wrapperInputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  margin-top: 24px;
}
