.button {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}

.button.error {
    border-color: var(--color-error);
}


.iconButton {
    position: absolute;
    left: 0;
    top: 6px;
}

