.titleStyle {
  color: #28293d;
  font-size: 20px;
  font-weight: bold;
}

.infoStyle {
  color: #718096;
  font-size: 16px;
  font-weight: 360;
}

.container {
  user-select: none;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mTop24 {
  margin-top: 24px;
}

.oveflowOff {
  border: 1px solid #ebebf0;
  border-radius: 8px;
  /* padding: 32px 25px !important; */
  overflow: visible;
}

/* .datePickerWrapper {
  transform: translateY(-100px);
} */

.mTop6 {
  margin-top: 6px;
}
