.card {
  display: flex;
  cursor: default;
}
.img {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  object-fit: cover;
  cursor: pointer;
}
.containerInfo {
  margin-left: 8px;
}

.title {
  margin-bottom: 2px;
  color: #28293d;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}

.subTitle {
  color: #718096;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 8px;
}

.containerStages {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.mRNone {
  margin-right: 0px;
}
