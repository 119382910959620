.root {
  position: sticky;
  top: 0;
  flex-shrink: 0;
  width: var(--aside-width);
  height: 100vh;
  background-color: #ffffff;
  /* border-right: 1px solid var(--color-text-light300); */

  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: var(--z-index-aside);
}

.small {
  width: var(--aside-width-small);
}

.inner {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border-right: 1px solid #f2f2f5;

  transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

.innerOpen {
  width: var(--aside-width);
}

.divider {
  margin: 1rem 0;
}

.header {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 1.375rem;
  min-height: 3.5rem;
  border-bottom: 1px solid var(--color-text-light300);
}

.small .header {
  padding-right: 0.25rem;
}

.menuButton {
  position: absolute;
  left: calc(var(--aside-width) - 12px);
  top: 1rem;

  width: 24px;
  height: 24px;
  padding: 0.125rem 0.125rem 0.125rem 0;
  color: #ffffff;
  font-size: 0.875rem;
  opacity: 0;
  background-color: var(--color-blue-500);

  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: calc(var(--z-index-aside) + 1);
}

.menuButton:hover {
  background-color: var(--color-blue-600);
}

.small .menuButton {
  transform: translateX(
      calc((var(--aside-width) - var(--aside-width-small)) * -1)
    )
    rotate(180deg);
  opacity: 0;
}

.innerOpen .menuButton {
  opacity: 1;
}

@keyframes animateButtonHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.small .menuButtonTranslate {
  transform: translateX(0) rotate(180deg);
}

.body {
  padding: 15px 11px 0 11px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.divide {
  height: 1px;
  background-color: #edf2f7;
}

.footer {
  position: relative;
  margin-top: auto;
  padding: 1rem;
  border-top: 1px solid var(--color-text-light300);
}

.innerOpen .small .footer {
  padding: 0.375rem;
}

.small .footerIcon {
  position: absolute;
  right: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
}

.logout {
  color: var(--color-pink500);
}

.logout:hover,
.logout:focus {
  background-color: var(--color-pink100);
}

.smallMenuItem {
  display: flex;
  justify-content: flex-end;
  font-size: 0;
  grid-gap: 0;
}

.submenuInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
}
.submenuInner > svg {
  width: 25px;
  height: 25px;
  margin-top: 1rem;
}

.submenuTitle {
  background: #f1f8ff;
  border-radius: 12px;
  user-select: none;
}
.submenuTitle .submenu a:first-child {
  margin-top: 0.5rem;
}

.submenuOpen {
  padding: 0;
}

.submenu {
  position: relative;
  display: grid;
  align-items: center;
  padding: 0.5rem 2rem 0;
  font-size: 14px;
  white-space: nowrap;
  color: var(--color-black500);
  cursor: pointer;
  border-radius: 12px;
  background: #f1f8ff;
  user-select: none;
}
.profileMenuItem {
  grid-template-columns: 1fr auto;
  justify-content: space-between;
}

.footerMenu {
  width: 200px;
  top: auto !important;
  left: 100% !important;
  bottom: 1.25rem !important;
  border: none;
  padding: 0;
  /*border: 1px solid #fafafc;*/
  border-radius: 8px;
}
/*
.footerMenu {
    width: 220px;
    transform: translateX(50%);
    margin-top: 4px;
    border-radius: 8px;

  } */

.footerIcon {
  flex-shrink: 0;
}

.gray {
  color: #718096;
}

.pink {
  color: #d52f6d;
}
