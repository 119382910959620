.noImgContainer {
  width: 96px;
  height: 96px;
  background-color: var(--color-blue-100);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgContainer {
  width: 96px;
  height: 96px;
  border-radius: 16px;
  object-fit: cover;
  user-select: none;
}

.initialsWrapper {
  display: flex;
}

.initialsText {
  font-size: 32px;
  font-weight: bold;
  color: var(--color-blue-600);
}
