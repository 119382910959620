.section {
  padding-bottom: 24px;
  /*border-bottom: 1px solid #f2f2f5;*/
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: #394253;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.btnIcon {
  height: 24px;
  width: 24px;
  border-radius: 6px;
  background-color: #f1f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}
.btnIcon:hover {
  cursor: pointer;
  background-color: white;
  transition: 0.2s;
}
.btnIcon:hover svg {
  fill: grey;
  transition: 0.2s;
}

.btnIcon svg {
  fill: #0373fe;
  height: 16px;
  width: 16px;
}

.disableBB {
  border-bottom: 0;
}
