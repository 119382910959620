.wrapper {
  display: flex;
  flex-direction: column;

  border: 1px solid #ebebf0;
  border-radius: 16px;
  padding: 16px;
  gap: 8px;

  user-select: none;

  max-width: calc(50% - 14px);
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  color: #2a303c;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

.containerIcons {
  display: flex;
}

.containerIcon {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  display: flex;
  background-color: #fafafc;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.containerIcon:hover {
  transition: 0.2s;
  opacity: 0.5;
  cursor: pointer;
}

.colorWine {
  margin-left: 8px;
  background-color: #fae9ef;
}
.icon {
  margin-top: 4px;
}
.icon svg {
  width: 16px;
  height: 16px;
}

.containerRow {
  gap: 8px;
  display: flex;
  padding-bottom: 4px;
  border-bottom: 1px solid #f2f2f5;
}

.label {
  width: 162px;
  min-width: 162px;
  color: #718096;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}

.value {
  color: #28293d;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
}

.grayText {
  color: #718096;
}
