.containerOptions {
  position: absolute;
  width: 100%;
  display: none;
  z-index: 100;
  border: 1px solid #fafafc;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 12px 42px -4px rgba(24, 39, 75, 0.12),
    0 8px 18px -6px rgba(24, 39, 75, 0.12);
  opacity: 0;
}

.showOptions {
  display: block;
  opacity: 1;

}

.animatedShowOptions {
  animation: animationBackground 0.2s forwards;
}
@keyframes animationBackground {
  0% {
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
