.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: auto;
  grid-gap: 16px;
  padding-bottom: 2rem;
  border-bottom: 1px solid #f2f2f5;
}

.card {
  display: grid;
  margin-top: 16px;
  padding: 12px;
  width: auto;
  border: 1px solid #f2f2f5;
  border-radius: 16px;
  user-select: text;
}

.cardHeader {
  display: flex;
  align-items: center;
}
.cardIcon {
  margin-top: -3px;
  width: 16px;
  height: 16px;
}

.cardIcon path {
  height: 14px;
  width: 14px;
  fill: #718096;
}

.contactName {
  color: #28293d;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 8px;
}

.contactTime {
  color: #a0aec0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  user-select: none;
}

.contactRole {
  color: #a0aec0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 4px 0;
}
.email{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.email > svg{
  cursor: pointer;
}
.contactEmail {
  color: #a0aec0;
  font-size: 14px;
  text-decoration-line: underline;
  user-select: text;
}

@media (max-width: 1150px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}
